<template>
  <fieldset>
    <h2>Faut-il refaire le plafond ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="ceiling" :value="true" v-model="quote.bathroom.finitions.ceiling.toDo" @click.once="checkForm">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="ceiling" :value="false" v-model="quote.bathroom.finitions.ceiling.toDo" @click.once="checkForm">
        <label for="no">Non</label>
      </li>
    </ul>
    <p class="moreInformations">Compris dans ce poste : quatre spots encastrés</p>

  </fieldset>
</template>

<script>



export default {

  props: ['quote'],
  methods: {
    checkForm() {
      if(this.quote.bathroom.conception.douche.toDo) {
        this.quote.bathroom.conception.douche.quantity = 1;
      } else {
        this.quote.bathroom.conception.douche.quantity = 0;
      }
      if(this.quote.bathroom.conception.baignoire.toDo) {
        this.quote.bathroom.conception.baignoire.quantity = 1;
      } else {
        this.quote.bathroom.conception.baignoire.quantity = 0;
      }
      if(this.quote.bathroom.conception.wc.toDo) {
        this.quote.bathroom.conception.wc.quantity = 1;
      } else {
        this.quote.bathroom.conception.wc.quantity = 0;
      }
      this.quote.validStep = 11; 
      this.quote.isValid = true;
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il climatiser un salon&nbsp;?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="living" :value="true" v-model="quote.airConditioning.rooms.living.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="living" :value="false" v-model="quote.airConditioning.rooms.living.toDo" @click="validateStep4">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.airConditioning.rooms.living.toDo" class="moreQuestions">
      <h3>Quelle est sa surface&nbsp;?</h3>
      <ul >
        <li>
          <input type="radio" id="small" name="livingSize" value="small" v-model="quote.airConditioning.rooms.living.size" @click="validateStep4">
          <label for="small">Plus petit que 40m²</label>
        </li>
        <li>
          <input type="radio" id="big" name="livingSize" value="big" v-model="quote.airConditioning.rooms.living.size" @click="validateStep4">
          <label for="big">Plus grand ou égal à 40m²</label>
        </li>
      </ul>
    <p class="moreInformations">Concerne uniquement la surface du salon, même s'il est ouvert sur la salle à manger.</p>

    </div>
  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep4() {
      this.quote.validStep = 4; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
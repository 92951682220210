<template>
  <fieldset>
    <h2>Parmi les pièces suivantes, lesquelles faut-il équiper ?</h2>
    <ul>
        <li>
          <input type="checkbox" id="living" name="living" value="living" v-model="quote.electricity.rooms.living.toDo">
          <label for="living">Salon</label>
        </li>
        <li>
          <input type="checkbox" id="diningRoom" name="diningRoom" value="diningRoom" v-model="quote.electricity.rooms.diningRoom.toDo">
          <label for="diningRoom">Salle à manger</label>
        </li>
        <li>
          <input type="checkbox" id="luandry" name="luandry" value="luandry" v-model="quote.electricity.rooms.luandry.toDo">
          <label for="luandry">Buanderie</label>
        </li>
        <li>
          <input type="checkbox" id="grenier" name="grenier" value="grenier" v-model="quote.electricity.rooms.grenier.toDo">
          <label for="grenier">Grenier</label>
        </li>
        <li>
          <input type="checkbox" id="bureau" name="bureau" value="bureau" v-model="quote.electricity.rooms.bureau.toDo">
          <label for="bureau">Bureau</label>
        </li>
        <li>
          <input type="checkbox" id="garage" name="garage" value="garage" v-model="quote.electricity.rooms.garage.toDo">
          <label for="garage">Garage</label>
        </li>
        <li>
          <input type="checkbox" id="cellar" name="otherRooms" value="cellar" v-model="quote.electricity.rooms.cellar.toDo">
          <label for="cellar">Cave</label>
        </li>
        <li>
          <input type="checkbox" id="gardenShed" name="otherRooms" value="gardenShed" v-model="quote.electricity.rooms.gardenShed.toDo">
          <label for="gardenShed">Abri de jardin</label>
        </li>
      </ul>
      <div class="btn-group">
        <button class="btn btn-action btn-secondary" @click.prevent="validateStep7">
        Valider
        </button>
      </div>
  </fieldset>
</template>

<script>



export default {

  props: ['currentStep'],
  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    validateStep7() {
      if(this.quote.validStep < this.currentStep) {
        this.quote.validStep = 7; 
      }
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il installer une baignoire ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="baignoire" :value="true" v-model="quote.bathroom.conception.baignoire.toDo" @click="validateStep6">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="baignoire" :value="false" v-model="quote.bathroom.conception.baignoire.toDo" @click="validateStep6">
        <label for="no">Non</label>
      </li>
    </ul>

  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    validateStep6() {
      this.quote.validStep = 6; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il climatiser une salle à manger&nbsp;?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="diningRoom" :value="true" v-model="quote.airConditioning.rooms.diningRoom.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="diningRoom" :value="false" v-model="quote.airConditioning.rooms.diningRoom.toDo" @click="checkForm">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.airConditioning.rooms.diningRoom.toDo" class="moreQuestions">
      <h3>Est-elle ouverte sur la cuisine&nbsp;?</h3>
      <ul >
        <li>
          <input type="radio" id="open" name="kitchenOpen" :value="true" v-model="quote.airConditioning.rooms.diningRoom.kitchenOpen" @click="checkForm">
          <label for="open">Oui</label>
        </li>
        <li>
          <input type="radio" id="close" name="kitchenOpen" :value="false" v-model="quote.airConditioning.rooms.diningRoom.kitchenOpen" @click="checkForm">
          <label for="close">Non</label>
        </li>
      </ul>
    </div>

  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    checkForm() {
      if(this.quote.airConditioning.rooms.bedroom.quantity > 0) {
        this.quote.airConditioning.rooms.bedroom.toDo = true;
      } else {
        this.quote.airConditioning.rooms.bedroom.toDo = false;
      }
      if(this.quote.airConditioning.rooms.bureau.toDo) {
        this.quote.airConditioning.rooms.bureau.quantity = 1;
      } else {
        this.quote.airConditioning.rooms.bureau.quantity = 0;
      }
      if(this.quote.airConditioning.rooms.living.toDo) {
        this.quote.airConditioning.rooms.living.quantity = 1;
      } else {
        this.quote.airConditioning.rooms.living.quantity = 0;
      }
      if(this.quote.airConditioning.rooms.diningRoom.toDo) {
        this.quote.airConditioning.rooms.diningRoom.quantity = 1;
      } else {
        this.quote.airConditioning.rooms.diningRoom.quantity = 0;
      }

      this.quote.validStep = 5; 
      this.quote.isValid = true;
    }
  }
}
</script>

<style>

</style>
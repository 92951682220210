<template>
  <fieldset>
    <h2>Faut-il installer une douche ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="douche" :value="true" v-model="quote.bathroom.conception.douche.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="douche" :value="false" v-model="quote.bathroom.conception.douche.toDo" @click="validateStep5">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.bathroom.conception.douche.toDo" class="moreQuestions">
      <h3>Quel type de douche ?</h3>
      <ul >
        <li>
          <input type="radio" id="cabine" name="doucheType" value="cabine" v-model="quote.bathroom.conception.douche.type" @click="validateStep5">
          <label for="cabine">Cabine</label>
        </li>
        <li>
          <input type="radio" id="italienne" name="doucheType" value="italienne" v-model="quote.bathroom.conception.douche.type" @click="scrollToNextQuestion(1)">
          <label for="italienne">A l'italienne (sol carrelé)</label>
        </li>
        <li>
          <input type="radio" id="receveur" name="doucheType" value="receveur" v-model="quote.bathroom.conception.douche.type" @click="scrollToNextQuestion(1)">
          <label for="receveur">Receveur de douche</label>
        </li>
      </ul>
    </div>
    <div v-show="quote.bathroom.conception.douche.type === 'italienne' || quote.bathroom.conception.douche.type === 'receveur'" class="moreQuestions">
      <h3>Quel type de paroi faut-il installer ?</h3>
      <ul >
        <li>
          <input type="radio" id="simple" name="paroiType" value="simple" v-model="quote.bathroom.conception.douche.paroi.type" @click="scrollToNextQuestion(2)">
          <label for="simple">Simple</label>
        </li>
        <li>
          <input type="radio" id="double" name="paroiType" value="double" v-model="quote.bathroom.conception.douche.paroi.type" @click="scrollToNextQuestion(2)">
          <label for="double">Double</label>
        </li>
      </ul>
    </div>
    <div v-show="quote.bathroom.conception.douche.paroi.type != null && (quote.bathroom.conception.douche.type === 'italienne' || quote.bathroom.conception.douche.type === 'receveur')" class="moreQuestions">
      <h3>Quelle finition murale prévoyez-vous pour votre douche ?</h3>
      <ul >
        <li>
          <input type="radio" id="faience" name="wall" value="faience" v-model="quote.bathroom.conception.douche.wall" @click="validateStep5">
          <label for="faience">Faïence</label>
        </li>
        <li>
          <input type="radio" id="panneau" name="wall" value="panneau" v-model="quote.bathroom.conception.douche.wall" @click="validateStep5">
          <label for="panneau">Panneaux muraux</label>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep5() {
      this.quote.validStep = 5; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
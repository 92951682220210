<template>
  <section class="form formPersonnalInformartions">
    <div class="container">
      <form class="personnalForm" @submit.prevent="verifyForm">
        <h2>Vous êtes proche du but !</h2>
        <p>Dès que vous aurez validé les informations ci-dessous, vous pourrez découvrir les détails de votre devis.</p>
        <div class="form-group-double">
          <div class="form-group required">
            <label for="name">Nom et prénom</label>
            <input type="text" id="name" name="name" v-model="quoteUserInformations.name" required>
          </div>
          <div class="form-group required">
            <label for="postCode">Code postal</label>
            <input type="text" id="postCode" name="postCode" v-model="quoteUserInformations.postCode" required>
          </div>
        </div>
        <div class="form-group required">
          <label for="email">Email</label>
          <input type="email" id="email" name="email" v-model="quoteUserInformations.emailAddress" required>
        </div>
        <div class="form-group required checkbox-form">
          <label for="rgpd">En cochant cette case, je consens à ce que mes données personnelles soient utilisées, <a href="/rgpd" target="blank">en accord avec le RGPD</a>, uniquement pour les relations commerciales avec DBD Tech. Ces données ne seront pas vendues à des tiers.</label>
          <input type="checkbox" id="rgpd" name="rgpd" v-model="quoteUserInformations.rgpd" required>
        </div>
        <div class="buttons-group">
          <button class="btn btn-action btn-secondary" :disabled="!quoteUserInformations.rgpd">Je découvre mon devis personnalisé !</button>
        </div>
      </form>
      <ul class="inputError">
        <li v-for="error, index in errors" :key="index">{{ error }}</li>
      </ul>
    </div>
    <pdf-quote-air-conditioning></pdf-quote-air-conditioning>
    <pdf-quote-bathroom></pdf-quote-bathroom>
    <pdf-quote-electricity></pdf-quote-electricity>
  </section>
  
</template>

<script>

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import emailjs from 'emailjs-com';
import pdfQuoteAirConditioning from '../components/Quote/QuoteAirConditioning/PdfQuoteAirConditioning.vue';
import pdfQuoteBathroom from '../components/Quote/QuoteBathroom/PdfQuoteBathroom.vue';
import pdfQuoteElectricity from '../components/Quote/QuoteElectricity/PdfQuoteElectricity.vue';

// import pdf2base64 from 'pdf-to-base64';

export default {
  components: {
    pdfQuoteAirConditioning,
    pdfQuoteBathroom,
    pdfQuoteElectricity
  },
  data() {
    return {
      errors: []
    }
  },
  computed: {
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    logo() {
      return this.$store.state.logo;
    }
  },
  methods: {
    verifyForm() {
      this.errors = [];
      if(!this.quoteUserInformations.name) {
        this.errors.push('Veuillez remplir un nom');
      }
      if(!this.quoteUserInformations.postCode) {
        this.errors.push('Veuillez remplir un code postal');
      } else {
        const regex = new RegExp('^\\d{4}$');

        if (!regex.test(this.quoteUserInformations.postCode)) { 
          this.errors.push('Votre code postal doit être composé de 4 chiffres');
        }
      }
      if(!this.quoteUserInformations.emailAddress) {
        this.errors.push('Veuillez remplir une adresse email valide');
      } else {
        const regex = new RegExp('^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

        if (!regex.test(this.quoteUserInformations.emailAddress)) { 
          this.errors.push('Votre adresse email ne semble pas correcte');
        }
      }
      if(!this.quoteUserInformations.rgpd) {
        this.errors.push('Veuillez cocher la case RGPD');
      }
      if(!this.errors.length) {
        console.log('Ce formulaire est valide !');
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '/' + mm + '/' + yyyy;
        this.quote.date = today;
        this.quote.quoteNumber = Date.now();
        if(this.quote.type === "bathroom") {
          this.generateReportBathroom();
          this.$router.push('votre-devis-salle-de-bain-personnalise');
        } else if(this.quote.type === "airConditioning") {
          this.generateReportAirConditioning();
          this.$router.push('votre-devis-climatisation-personnalise');
        } else if(this.quote.type === "electricity") {
          this.generateReportElectricity();
          this.$router.push('votre-devis-electricite-personnalise');
        }
        
      }
    },
    generateReportAirConditioning () {

      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);

      doc.autoTable({
        head: [['Fourniture et pose des unités intérieures pour ...']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120,
      });
      autoTable(doc, { html: '#quoteToPdfPieces', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right' }, 4: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Fourniture et pose des unités extérieures']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfExterieur', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Travaux divers']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfDivers', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Options']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfOptions', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      
      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});
      

      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });

      autoTable(doc, { html: '#quoteToPdfFiches', alternateRowStyles: {fillColor: false}});

      // Envoi et sauvegarde du PDF
      let pdfName = this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
      let pdfBase64 = doc.output('datauristring');
      this.sendPdf(pdfBase64, pdfName, 'template_0dsylcs');
      this.sendPdf(pdfBase64, pdfName, 'template_4mq3hom');
    },
    generateReportBathroom () {

      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);

      doc.autoTable({
        head: [['Informations de base']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120
      });
      autoTable(doc, { html: '#quoteToPdfBasis', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      doc.autoTable({
        head: [['Préparation du chantier']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfPreparation', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      
      doc.autoTable({
        head: [['Conception']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfConception',  headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right', minCellWidth: 30 }, 4: { halign: 'right', minCellWidth: 30 } }});
      
      doc.autoTable({
        head: [['Finitions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfFinition', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 2: { halign: 'right', minCellWidth: 30 } }});
      
      if(this.quote.bathroom.supplements.plumbing.toDo) {
        doc.autoTable({
          head: [['Travaux divers']],
          headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
        });
        autoTable(doc, { html: '#quoteToPdfDiversBathroom', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'right', minCellWidth: 30 }}});
      }
      
      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});
      
      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. Il est toujours possible de modifier les accessoires après notre visite, sous réserve d\'adaptation des prix. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });

      // Envoi et sauvegarde du PDF
      let pdfName = this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
      let pdfBase64 = doc.output('datauristring');
      this.sendPdf(pdfBase64, pdfName, 'template_0dsylcs');
      this.sendPdf(pdfBase64, pdfName, 'template_4mq3hom');
    },
    generateReportElectricity () {

      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);

      doc.autoTable({
        head: [['Récapitulatif']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120,
      });
      autoTable(doc, { html: '#quoteToPdfRecapitulatif', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'right', minCellWidth: 30 }, 3: { halign: 'right', minCellWidth: 30}}});
    
      if(this.quote.electricity.alarm.toDo || this.quote.electricity.supplements.toDo || this.quote.electricity.technic.reception) {
        doc.autoTable({
          head: [['Options']],
          headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        });
        autoTable(doc, { html: '#quoteToPdfOptionsElectricity', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      }
      
      doc.autoTable({
        head: [['Tableau électrique']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
      });
      autoTable(doc, { html: '#quoteToPdfTableauElectricity', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30}}});
    
      doc.autoTable({
        head: [['Description des éléments par pièce']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
      });
      autoTable(doc, { html: '#quoteToPdfPiecesDescription', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right' }, 4: { halign: 'right', minCellWidth: 30 } }});
          
      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});
      

      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });


      // Envoi et sauvegarde du PDF
      let pdfName = this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
      let pdfBase64 = doc.output('datauristring');
      this.sendPdf(pdfBase64, pdfName, 'template_0dsylcs');
      this.sendPdf(pdfBase64, pdfName, 'template_4mq3hom');
    },
    sendPdf(pdfBase64, pdfName, templateId) {
      let waiting = document.querySelector('#waiting');
      waiting.classList.add('active');
      let typeQuote = '';
      if(this.quote.type === "bathroom") {
        typeQuote = "SDB";
      } else if (this.quote.type === "airConditioning") {
        typeQuote = "AirCo";
      } else if (this.quote.type === "electricity") {
        typeQuote = "Elec";
      }
      emailjs.send('service_0g7pobn', templateId, {
                  to_name: "Jérôme",
                  user_name: this.quoteUserInformations.name,
                  user_postCode: this.quoteUserInformations.postCode,
                  user_email: this.quoteUserInformations.emailAddress,
                  pdf_file: pdfBase64,
                  pdf_name: pdfName,
                  type: typeQuote
              }, 'user_HbY4OMU3yW41VYggzuODG').then((result) => {
                      console.log('SUCCESS!', result.status, result.text);
                      waiting.classList.remove('active');
                  }, (error) => {
                      console.log('FAILED...', error);
                      waiting.classList.remove('active');
                  });
    
    }
  }
}
</script>

<style>

</style>
<template>
  <footer>
    <router-link to="/" class="logo">
      <img src="../../assets/logo-dbd-tech-white.svg" alt="DBD Tech">
    </router-link>
    <p class="address">
      rue de la Basse Sambre, 10<br>
      5150 Floreffe (Belgique)<br>
      TVA BE 0765 717 010
    </p>
    <p class="credentials">
      DBD Tech &copy; 2021 - <router-link to="/rgpd" class="link link-light">RGPD</router-link><br>
      Version 1.1.0
    </p>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
    <section class="page rgpd">
      <div class="container">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li>RGPD</li>
        </ul>
        <header>
          <h1>DBD Tech : RGPD</h1>
        </header>
        <h2>Responsable de traitement</h2>
        <p>Le responsable du traitement de vos données personnelles est DBD Tech srl, rue de la Basse Sambre, 10 à 5150 – Floreffe (Numéro d’entreprise : BE 0765 717 010).</p>

        <p>Les informations et données personnelles que vous transmettez par ce site sont traitées en respectant les dispositions légales applicables en Belgique, à savoir le Règlement Général de Protection des Données (« RGPD ») et la loi du 8 décembre 1992 relative à la protection de la vie privée à l'égard des traitements de données à caractère personnel.</p>

        <h2>Données personnelles collectées activement auprès de l’utilisateur</h2>
        <p>DBD Tech a accès aux données que vous nous communiquez volontairement et conserve les données que vous nous fournissez lorsque vous créez transmettez un formulaire de demande de devis, de demande d'appel, ou de tout autre contact.</p>

        <p>Les catégories de données personnelles suivantes peuvent être collectées dans ce cadre :</p>

        <ul>
            <li>Données d’identification : nom, prénom, code postal</li>
            <li>Données de contact : adresse de courriel, numéro de téléphone</li>
        </ul>   
        <h2>Utilisation de vos données</h2>
        <p>DBD Tech conserve vos données personnelles collectées; cette conservation permet de gérer les contacts commerciaux et de revenir vers vous suite à votre demande.</p>

        <p>Ces données ne sont communiquées à aucun tiers.</p>

        <h2>Droits de consultation, modification, suppression...</h2>
        <p>Conformément à la règlementation sur la protection des données à caractère personnel, vous pouvez accéder aux informations vous concernant, les consulter, les rectifier, les récupérer ou les transférer à un tiers. Vous pouvez également les faire supprimer ou vous opposer au traitement des données qui ne sont pas pertinentes par rapport aux objectifs définis.</p>

        <p>Pour ce faire, il vous suffit d’envoyer un email à l'adresse info@dbdtech.be.</p>

      </div>
      
      
    </section>
</template>

<script>

export default {
  
}
</script>

<style>

</style>
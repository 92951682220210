<template>
  <section class="slider">
    <div class="container">
      <h1><span>DBD Tech&nbsp;:</span> le partenaire de vos travaux !</h1>
      <h2>Pour quel projet voulez-vous obtenir un devis ?</h2>
      <ul>
        <home-slider-element v-for="service in services" :key="service.id" :service="service"></home-slider-element>
      </ul>
    </div>
    <p class="sliderInfo">D'autres services arriveront très prochainement !</p>
  </section>
</template>

<script>

import HomeSliderElement from './HomeSliderElement.vue';

export default {
  components: {
    HomeSliderElement
  },
  props: ['services']
}
</script>

<style>

</style>
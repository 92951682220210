<template>
  <fieldset>
    <h2>Quel niveau de rénovation souhaitez-vous ?</h2>
    <ul>
      <li>
        <input type="radio" id="low" name="renovation" value="low" v-model="quote.electricity.renovation.level" @click.once="validateStep2">
        <label for="low">Rénovation partielle</label>
      </li>
      <li>
        <input type="radio" id="full" name="renovation" value="full" v-model="quote.electricity.renovation.level" @click.once="validateStep2">
        <label for="full">Rénovation complète</label>
      </li>
    </ul>
    <p class="moreInformations">Une rénovation partielle ne comprend que quelques pièces de la maison et n'inclut pas le tableau électrique.</p>

  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    validateStep2() {
      this.quote.validStep = 2; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il prévoir des alimentations supplémentaires ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="supplements" :value="true" v-model="quote.electricity.supplements.toDo" @click="validateStep8">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="supplements" :value="false" v-model="quote.electricity.supplements.toDo" @click="validateStep8">
        <label for="no">Non</label>
      </li>
    </ul>
    <p class="moreInformations">Par exemple : borne voiture, climatisation, chauffage électrique, éclairage extérieur, PAC piscine ...<br>Le budget de ce poste sera défini lors de la visite sur place.</p>
    
  
  </fieldset>
  
</template>

<script>



export default {
  props: ['currentStep'],
  computed: {
    quote() {
      return this.$store.state.quote;
    }
  },
  methods: {
    validateStep8() {
      if(this.quote.validStep < this.currentStep) {
        this.quote.validStep = 8; 
      }
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
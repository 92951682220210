<template>
  <div>
    <home-slider :services="services"></home-slider>
    <home-quote-steps :quote-steps="quoteSteps"></home-quote-steps>
    <home-call-me></home-call-me>
  </div>
</template>

<script>
import HomeCallMe from '../components/Home/HomeCallMe.vue';
import HomeSlider from '../components/Home/HomeSlider.vue';
import HomeQuoteSteps from '../components/Home/HomeQuoteSteps';

export default {
  components: {
    HomeCallMe,
    HomeSlider,
    HomeQuoteSteps
  },
  computed: {
    services() {
      return this.$store.state.services;
    },
    quoteSteps() {
      return this.$store.state.quoteSteps;
    }
  }
}
</script>

<style>

</style>
<template>
  <li :style="{ 'background-image': 'url(' + step.img + ')' }">
    <div class="stepId">{{ step.id }}</div>
    <h2>{{ step.title }}</h2>
    <p>{{ step.description }}</p>
  </li>
</template>

<script>

export default {
    props: ['step']
}
</script>

<style>

</style>
<template>
  <section class="callMe">
    <div class="container">
      <header>
        <h1>Besoin de renseignements complémentaires ?</h1>
        <h2>Nous vous rappelons dans les 48h !</h2>
      </header>
      <form id="formCallMe" @submit.prevent="verifyForm">
        <div class="form-group-double">
          <div class="form-group required">
            <label for="name">Nom et prénom</label>
            <input type="text" id="name" name="name" v-model="userInformations.name" required>
          </div>
          <div class="form-group required">
            <label for="postCode">Code postal</label>
            <input type="text" id="postCode" name="postCode" v-model="userInformations.postCode" required>
          </div>
        </div>
        <div class="form-group required">
          <label for="phoneNumber">Numéro de téléphone</label>
          <input type="tel" id="phoneNumber" name="phoneNumber" v-model="userInformations.phoneNumber" required>
        </div>
        <div class="form-group not-required" >
          <label for="email">Email</label>
          <input type="email" id="email" name="email" v-model="userInformations.email">
        </div>
        <div class="form-group required">
          <label for="comment">Expliquez-nous brièvement votre demande</label>
          <textarea name="comment" id="comment" cols="30" rows="10" v-model="userInformations.comment" required></textarea>
        </div>
        <div class="buttons-group">
          <button class="btn btn-action btn-dark">Appelez-moi !</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      userInformations: {
        name: '',
        postCode: '',
        phoneNumber: '',
        comment: '',
        email: ''
      },
      errors: [],

    }
  },
  methods: {
    verifyForm() {
      this.errors = [];
      if(!this.userInformations.name) {
        this.errors.push('Veuillez remplir un nom');
      }
      if(!this.userInformations.postCode) {
        this.errors.push('Veuillez remplir un code postal');
      }
      if(!this.userInformations.phoneNumber) {
        this.errors.push('Veuillez remplir un numéro de téléphone');
      }
      if(!this.errors.length) {
        this.sendEmail();
        this.userInformations = {
          name: '',
          postCode: '',
          phoneNumber: '',
          comment: '',
          email: ''
        }
      }
    },
    sendEmail: () => {
      emailjs.sendForm('service_1zuvhku', 'template_f7hphn5', '#formCallMe',
        'user_HbY4OMU3yW41VYggzuODG')
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
    }
  }
}
</script>

<style>

</style>
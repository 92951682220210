<template>
<div id="pdfQuoteAirConditioning">
  <table id="quoteToPdfPieces" class="quotePdf">
        
        <thead>
          <tr>
            <th>Intitulé</th>
                <th>Gamme</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix total</th>
          </tr>
        </thead>
        <tbody>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.bedroom.quantity">
                <td>Chambres</td>
                <td>
                  {{ quote.airConditioning.rooms.bedroom.gamme == 1 ? "Standard" : "Design" }}
                </td>
                <td>{{ quote.airConditioning.rooms.bedroom.quantity }}</td>
                <td>{{ priceOfUnitInBedroom | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfUnitInBedroom | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.bureau.quantity">
                <td>Bureau</td>
                <td>
                  {{ quote.airConditioning.rooms.bureau.gamme == 1 ? "Standard" : "Design" }}
                </td>
                <td>{{ quote.airConditioning.rooms.bureau.quantity }}</td>
                <td></td>
                <td>{{ totalPriceOfUnitInBureau | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.living.quantity">
                <td v-if="quote.airConditioning.rooms.living.size === 'small'">Salon de moins de 40m²</td>
                <td v-else>Salon égal ou supérieur à 40m²</td>
                <td>
                  {{ quote.airConditioning.rooms.living.gamme == 1 ? "Standard" : "Design" }}
                </td>
                <td>{{ quote.airConditioning.rooms.living.quantity }}</td>
                <td></td>
                <td>{{ totalPriceOfUnitInLiving | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.diningRoom.quantity">
                <td v-if="quote.airConditioning.rooms.diningRoom.kitchenOpen">Salle à manger ouverte sur la cuisine</td>
                <td v-else>Salle à manger individuelle</td>
                <td>
                  {{ quote.airConditioning.rooms.diningRoom.gamme == 1 ? "Standard" : "Design" }}
                </td>
                <td>{{ quote.airConditioning.rooms.diningRoom.quantity }}</td>
                <td></td>
                <td>{{ totalPriceOfUnitInDiningRoom | numeral('0.00') }} €</td>
              </tr>
            </tbody>
      </table>
      <table id="quoteToPdfExterieur" class="quotePdf">
        <thead>
<tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
        </thead>
        <tbody>
          <tr class="twoCols">
                <td>Unités extérieures<br>
                  <p>Le nombre d'unités extérieures sera déterminé lors de la visite sur place.</p>
                </td>
                <td>{{ totalPriceOfUnitOut | numeral('0.00') }} €</td>
              </tr>
        </tbody>
      </table>
      <table id="quoteToPdfDivers" class="quotePdf">
        <thead>
<tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
        </thead>
        <tbody>
          <tr class="twoCols">
                <td>Lignes frigorifiques<br>
                  <p>Fourniture et pose entre unité(s) intérieure(s) et extérieure(s)</p>
                </td>
                <td>{{ totalPriceOfLignesFrigorifiques | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Câblage<br>
                  <p>Fourniture, pose et raccordement entre unité(s) intérieure(s) et extérieure(s)</p>
                </td>
                <td>{{ totalPriceOfCablageUnitIn | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Condensat<br>
                  <p>Fourniture et pose du réseau</p>
                </td>
                <td>{{ totalPriceOfCondensat | numeral('0.00') }} €</td>
              </tr>
        </tbody>
      </table>
      <table id="quoteToPdfOptions" class="quotePdf">
        <thead>
<tr>
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
        </thead>
        <tbody>
          <tr>
                <td>
                  <p v-if="!quote.airConditioning.options.alimElecExt">Vous devez prévoir l'alimentation électrique au niveau des unités extérieures.</p>
                <p v-else>Nous nous chargerons de l'installation des alimentations électriques pour les unités extérieures.</p>
               </td>
                <td>{{ totalPriceOfAlimElecExt | numeral('0.00') }} €</td>
              </tr>
        </tbody>
      </table>
      <table id="quoteToPdfPrices" class="quotePdf">
        <tbody>
              <tr class="twoCols">
                <td>Prix total Htva</td>
                <td>{{ totalPriceHtva | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Prix total Tvac {{ pourcentageOfTva }}%</td>
                <td>{{ totalPriceTvac | numeral('0.00') }} €</td>
              </tr>
            </tbody>
      </table>
      <table id="quoteToPdfFiches" class="quotePdf">
        <tbody>
          <tr>
            <td>Fiche technique standard : https://www.dbdtech.be/fiches/dbdtech-climatisation-standard.pdf</td>
          </tr>
          <tr>
            <td>Fiche technique design : https://www.dbdtech.be/fiches/dbdtech-climatisation-design.pdf</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'totalPriceHtva',
      'totalPriceTvac',
      'typeOfConstruction',
      'pourcentageOfTva',
      'priceOfUnitInBedroom',
      'totalPriceOfUnitInBedroom',
      'totalPriceOfUnitInBureau',
      'totalPriceOfUnitInLiving',
      'totalPriceOfUnitInDiningRoom',
      'totalPriceOfUnitOut',
      'totalOfWatts',
      'totalPriceOfLignesFrigorifiques',
      'totalPriceOfCablageUnitIn',
      'totalPriceOfCondensat',
      'totalPriceOfAlimElecExt'
    ]), 
  }
}
</script>

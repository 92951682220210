<template>
  <fieldset>
    <h2>Faut-il une finition au sol de votre salle de bain ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="groundBathroom" :value="true" v-model="quote.bathroom.finitions.ground.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="groundBathroom" :value="false" v-model="quote.bathroom.finitions.ground.toDo" @click="validateStep9">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.bathroom.finitions.ground.toDo" class="moreQuestions">
      <h3>Quel type de finition au sol souhaitez-vous ?</h3>
      <ul >
        <li>
          <input type="radio" id="carrelage" name="groundType" value="carrelage" v-model="quote.bathroom.finitions.ground.type" @click="validateStep9">
          <label for="carrelage">Carrelage</label>
        </li>
        <li>
          <input type="radio" id="plancher" name="groundType" value="plancher" v-model="quote.bathroom.finitions.ground.type" @click="validateStep9">
          <label for="plancher">Plancher flottant</label>
        </li>
      </ul>
      <p class="moreInformations">Nous utilisons des planchers flottants spécifiques pour les zones humides.</p>
    </div>
  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep9() {
      this.quote.validStep = 9; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il équiper une cuisine ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="kitchen" :value="true" v-model="quote.electricity.rooms.kitchen.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="kitchen" :value="false" v-model="quote.electricity.rooms.kitchen.toDo" @click="validateStep6">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.electricity.rooms.kitchen.toDo" class="moreQuestions">
      <h3>Parmi les équipements suivants, lesquels sont présents ?</h3>
      <ul>
        <li>
          <input type="checkbox" id="oven" name="kitchenEquipment" value="oven" v-model="quote.electricity.rooms.kitchen.kitchenEquipment">
          <label for="oven">Four électrique</label>
        </li>
        <li>
          <input type="checkbox" id="hob" name="kitchenEquipment" value="hob" v-model="quote.electricity.rooms.kitchen.kitchenEquipment">
          <label for="hob">Taque électrique</label>
        </li>
        <li>
          <input type="checkbox" id="dishwasher" name="kitchenEquipment" value="dishwasher" v-model="quote.electricity.rooms.kitchen.kitchenEquipment">
          <label for="dishwasher">Lave-vaisselle</label>
        </li>
      </ul>
      <div class="btn-group">
        <button class="btn btn-action btn-secondary" @click.prevent="validateStep6">
        Valider
        </button>
      </div>
    </div>

  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep6() {
      this.quote.validStep = 6; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <li @click="resetState">
    <router-link :to="service.url">
      <img :src="service.img" :alt="service.title">
      <h2>{{ service.title }}</h2>
    </router-link>
  </li>
</template>

<script>

import { resetState } from '../../store.js';

export default {
  props: ['service'],
  computed: {
    resetState() {
      return resetState;
    }
  }
}
</script>

<style>

</style>
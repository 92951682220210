<template>
  <fieldset>
    <h2>Faut-il installer un WC ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="wc" :value="true" v-model="quote.bathroom.conception.wc.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="wc" :value="false" v-model="quote.bathroom.conception.wc.toDo" @click="validateStep8">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.bathroom.conception.wc.toDo" class="moreQuestions">
      <h3>Quel type de WC souhaitez-vous ?</h3>
      <ul >
        <li>
          <input type="radio" id="surPied" name="wcType" value="surPied" v-model="quote.bathroom.conception.wc.type" @click="validateStep8">
          <label for="surPied">WC sur pied</label>
        </li>
        <li>
          <input type="radio" id="suspendu" name="wcType" value="suspendu" v-model="quote.bathroom.conception.wc.type" @click="validateStep8">
          <label for="suspendu">WC suspendu</label>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep8() {
      this.quote.validStep = 8; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
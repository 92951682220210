<template>
  <header :class="{ 'dark': isDark }" id="mainHeader">
    <router-link to="/" class="logo" href="/">
      <img src="../../assets/logo-dbd-tech-white.svg" alt="DBD Tech">
    </router-link>
    <button class="navbar-toggler" @click.stop="toggleNav">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <nav>
      <ul class="navbar-nav" @click.stop="toggleNav">
        <li><router-link to="/" class="link link-nav" :class="{ 'link-nav-active': this.$route.path === '/' }">Accueil</router-link></li>
        <!-- <li><router-link to="/services" class="link link-nav" :class="{ 'link-nav-active': this.$route.path === '/services' }">Nos services</router-link></li> -->
        <!-- <li><router-link to="/realisations" class="link link-nav" :class="{ 'link-nav-active': this.$route.path === '/realisations' }">Nos réalisations</router-link></li> -->
        <li><router-link to="/a-propos" class="link link-nav" :class="{ 'link-nav-active': this.$route.path === '/a-propos' }">A propos</router-link></li>
        <li><router-link to="/devis-en-ligne" class="btn btn-action btn-secondary">Devis en ligne</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
 created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const header = document.querySelector('#mainHeader');
      if(window.scrollY > 100) {
        header.classList.add('active');
      } else {
        header.classList.remove('active');
      }
    },
    toggleNav() {
      const menuBtn = document.querySelector('.navbar-toggler');
      const menuElement = document.querySelector('#mainHeader nav');
      menuBtn.classList.toggle('active');
      menuElement.classList.toggle('active');
    }
  },
  computed: {
    isDark() {
      if(this.$route.path != '/' 
        && this.$route.path != '/devis-en-ligne'
        && this.$route.path != '/devis-salle-de-bain'
        && this.$route.path != '/devis-climatisation'
        && this.$route.path != '/informations-personnelles'
        && this.$route.path != '/votre-devis-salle-de-bain-personnalise'
        && this.$route.path != '/votre-devis-climatisation-personnalise') {
          return true;
        } else {
          return false;
        }
    }
  }
}
</script>

<style>

</style>
<template>
<div id="pdfQuoteElectricity">
  <table id="quoteToPdfRecapitulatif" class="quotePdf">
    <thead>
      <tr>
        <th>Intitulé</th>
        <th>Quantité</th>
        <th>Prix unitaire</th>
        <th>Prix total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Nombre total de prises - pose encastrée</td>
        <td>{{ totalOfPriseIn }}</td>
        <td>{{ totalPriceOfPriseIn / totalOfPriseIn | numeral('0.00') }} €</td>
        <td>{{ totalPriceOfPriseIn | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>Nombre total de prises - pose apparente</td>
        <td>{{ totalOfPriseOut }}</td>
        <td>{{ totalPriceOfPriseOut / totalOfPriseOut | numeral('0.00') }} €</td>
        <td>{{ totalPriceOfPriseOut | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>Nombre total d'interrupteurs - pose encastrée</td>
        <td>{{ totalOfInterrupteurIn }}</td>
        <td>{{ totalPriceOfInterrupteurIn / totalOfInterrupteurIn | numeral('0.00') }} €</td>
        <td>{{ totalPriceOfInterrupteurIn | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>Nombre total d'interrupteurs - pose apparente</td>
        <td>{{ totalOfInterrupteurOut }}</td>
        <td>{{ totalPriceOfInterrupteurOut / totalOfInterrupteurOut | numeral('0.00') }} €</td>
        <td>{{ totalPriceOfInterrupteurOut | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>Nombre total de points lumineux</td>
        <td>{{ totalOfPointLumineuxIn + totalOfPointLumineuxOut }}</td>
        <td>{{ (totalPriceOfPointLumineuxIn + totalPriceOfPointLumineuxOut) / (totalOfPointLumineuxIn + totalOfPointLumineuxOut) | numeral('0.00') }} €</td>
        <td>{{ totalPriceOfPointLumineuxIn + totalPriceOfPointLumineuxOut | numeral('0.00') }} €</td>
      </tr>
    </tbody>
  </table>
  <table id="quoteToPdfOptionsElectricity" class="quotePdf" v-if="quote.electricity.alarm.toDo || quote.electricity.supplements.toDo || quote.electricity.technic.reception">
    <thead>
      <tr>
        <th>Intitulé</th>
        <th>Prix total</th>
      </tr>
      
    </thead>
    <tbody>
      <tr v-if="quote.electricity.alarm.toDo">
        <td>Alarme</td>
        <td>{{ priceOfAlarm | numeral('0.00') }} €</td>
      </tr>
      <tr v-if="quote.electricity.supplements.toDo">
        <td>
          Prises supplémentaires<br>
          Par exemple : borne voiture, climatisation, chauffage électrique, éclairage extérieur, PAC piscine ...<br>
          Le budget de ce poste sera défini lors de la visite sur place.
        </td>
        <td>A déterminer</td>
      </tr>
      <tr v-if="quote.electricity.technic.reception">
        <td>
          Réception par un organisme agréé<br>
          Avec plans As-Built
        </td>
        <td>{{ priceOfReception | numeral('0.00') }} €</td>
      </tr>
    </tbody>
  </table>
  <table id="quoteToPdfTableauElectricity" class="quotePdf">
    <thead>
      <tr>
        <th>Intitulé</th>
        <th>Prix total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="quote.electricity.technic.tableau">
        <td>
          Coffret tableau<br>
          Comprenant le coffret, le disjoncteur de tête, les différentiels 300mA et 30mA et la prise de terre.
        </td>
        <td>{{ totalPriceOfTableau | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>
          Disjoncteurs<br>
          Pour l'ensemble des pièces et des équipements de la cuisine.
        </td>
        <td>{{ totalPriceOfDisjoncteurs16 + totalPriceOfDisjoncteurs25 | numeral('0.00') }} €</td>
      </tr>
    </tbody>
  </table>
  <table id="quoteToPdfPiecesDescription" class="quotePdf">
    <thead>
      <tr>
        <th>Intitulé</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(element, index) in quote.electricity.rooms.bedroom.listOfElecEquipment" :key="'bedroom'+index">
        <td>
          Chambre {{ index + 1}}<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.bathroom.listOfElecEquipment" :key="'bathroom'+index">
        <td>
          Salle de bain {{ index + 1}}<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.wc.listOfElecEquipment" :key="'wc'+index">
        <td>
          WC {{ index + 1}}<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.kitchen.listOfElecEquipment" :key="'wc'+index">
        <td>
          Cuisine<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.living.listOfElecEquipment" :key="'wc'+index">
        <td>
          Salon<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.diningRoom.listOfElecEquipment" :key="'wc'+index">
        <td>
          Salle à manger<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.luandry.listOfElecEquipment" :key="'wc'+index">
        <td>
          Buanderie<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.cellar.listOfElecEquipment" :key="'wc'+index">
        <td>
          Cave<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.grenier.listOfElecEquipment" :key="'wc'+index">
        <td>
          Grenier<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.bureau.listOfElecEquipment" :key="'wc'+index">
        <td>
          Bureau<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.garage.listOfElecEquipment" :key="'wc'+index">
        <td>
          Garage<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
      <tr v-for="(element, index) in quote.electricity.rooms.gardenShed.listOfElecEquipment" :key="'wc'+index">
        <td>
          Abri de jardin<br>
          Prises : {{ element.prise }} |
          Interrupteurs : {{ element.interrupteur }} |
          Points lumineux : {{ element.pointLumineux }}
        </td>
      </tr>
    </tbody>
  </table>
  <table id="quoteToPdfPrices" class="quotePdf">
    <tbody>
      <tr>
        <td>Prix total Htva</td>
        <td>{{ totalPriceHtva | numeral('0.00') }} €</td>
      </tr>
      <tr>
        <td>Prix total Tvac {{ pourcentageOfTva }}%</td>
        <td>{{ totalPriceTvac | numeral('0.00') }} €</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'totalOfPriseIn',
      'totalPriceOfPriseIn',
      'totalOfPriseOut',
      'totalPriceOfPriseOut',
      'totalOfInterrupteurIn',
      'totalPriceOfInterrupteurIn',
      'totalOfInterrupteurOut',
      'totalPriceOfInterrupteurOut',
      'totalOfPointLumineuxIn',
      'totalPriceOfPointLumineuxIn',
      'totalOfPointLumineuxOut',
      'totalPriceOfPointLumineuxOut',
      'typeOfRenovation',
      'priceOfTableau',
      'priceOfDisjoncteurTete',
      'priceOfDisjoncteurDiff63',
      'priceOfDisjoncteurDiff40',
      'priceOfDisjoncteur16',
      'totalOfDisjoncteurs16',
      'totalPriceOfDisjoncteurs16',
      'priceOfDisjoncteur25',
      'totalOfDisjoncteurs25',
      'totalPriceOfDisjoncteurs25',
      'priceOfCoffretGestionnaire',
      'priceOfCableCompteurTableau',
      'priceOfPriseTerre',
      'priceOfForfaitMiseTerre',
      'priceOfReception',
      'priceOfRechargeVoiture',
      'priceOfAlarm',
      'totalPriceOfTableau',
      'totalPriceHtva',
      'totalPriceTvac',
      'typeOfDemolition',
      'typeOfConstruction',
      'pourcentageOfTva'
    ]), 
  }
}
</script>
<template>
  <fieldset>
    <h2>De quand date le bâtiment ?</h2>
    <ul>
      <li>
        <input type="radio" id="underTen" name="dateOfConstruction" :value="1" v-model="quote.basisInformations.dateOfConstruction" @click="validateStep1">
        <label for="underTen">Moins de 10 ans</label>
      </li>
      <li>
        <input type="radio" id="overTen" name="dateOfConstruction" :value="2" v-model="quote.basisInformations.dateOfConstruction" @click="validateStep1">
        <label for="overTen">10 ans ou plus</label>
      </li>
    </ul>
    <p class="moreInformations">Moins de 10 ans = 21% de TVA<br>10 ans ou plus = 6% de TVA</p>
    

  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    validateStep1() {
      this.quote.type = "bathroom";
      this.quote.validStep = 1;
      this.$emit('next-step');
    }
  }
  
}
</script>

<style>

</style>
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    logo: {
      img: './img/logo_colors.png'
    },
    services: [
      {
        id: 2,
        img: './img/picto-bathroom.svg',
        title: 'Une salle de bain',
        url: '/devis-salle-de-bain',
        description: ''
      }, 
      {
        id: 3,
        img: './img/picto-air-conditioner.svg',
        title: 'La climatisation',
        url: '/devis-climatisation',
        description: ''
      }
    ],
    quoteSteps: [
      {
        id: 1,
        img: './img/picto-quote-step-1.svg',
        title: 'Sélectionnez le type de devis',
        description: 'Choisissez le devis qui vous intéresse parmi nos services.'
      },
      {
        id: 2,
        img: './img/picto-quote-step-2.svg',
        title: 'Répondez aux questions',
        description: 'Quelques questions vous seront alors posées pour définir vos besoins. A tout moment, vous pouvez reparcourir vos réponses avant de valider le formulaire.'
      },
      {
        id: 3,
        img: './img/picto-quote-step-3.svg',
        title: 'Découvrez votre estimation',
        description: 'Une estimation apparaît sur base de vos réponses. Il est encore possible d\'ajuster certains éléments, comme des quantités ou encore le niveau de finition des matériaux.'
      },
      {
        id: 4,
        img: './img/picto-quote-step-4.svg',
        title: 'Demandez à être contacté',
        description: 'Nous vous proposons de laisser vos coordonnées afin que l\'un de nos conseillers reprenne contact avec vous.'
      }
    ],
    callUserInformations: {
      name: '',
      postCode: '',
      phoneNumber: '',
      comment: ''
    },
    quoteUserInformations: {
      name: '',
      emailAddress: '',
      postCode: '',
      phoneNumber: '',
      rgpd: false
    },
    fiches: {
      airConditioning: {
        unitIn: {
          standard: "dbdtech-climatisation-standard.pdf",
          design: "dbdtech-climatisation-design.pdf"
        }
      }
    },
    quote: {
      type: null,
      validStep: 0,
      isValid: false,
      totalTvac: 0,
      totalHtva: 0,
      date: null,
      quoteNumber: null,
      version: 1,
      basisInformations: {
        dateOfConstruction: null
      },
      roomInformations: {
        length: 0,
        width: 0,
      },
      bathroom: {
        preparation: {
          demolition: {
            type: null
          }
        },
        conception: {
          douche: {
            toDo: null,
            type: null,
            paroi: {
              type: null,
              gamme: 1
            },
            wall: null,
            quantity: 0,
            gamme: 1
          },
          baignoire: {
            toDo: null,
            quantity: 0,
            gamme: 1
          },
          sink: {
            toDo: null,           
            vasque: {
              type: null,
              quantity: 0,
              gamme: 1
            },
            miror: {
              gamme: 1
            }
          },
          wc: {
            toDo: null,
            type: null,
            quantity: 0,
            gamme: 1
          }
        },
        finitions: {
          wall: {
            toDo: null,
            zones: null,
            type: null,
            gamme: 1
          },
          ground: {
            toDo: null,
            type: null,
            gamme: 1
          },
          ceiling: {
            toDo: null
          }
        },
        supplements: {
          plumbing: {
            toDo: null
          }
        }
      },
      airConditioning: {
        options: {
          alimElecExt: false
        },
        rooms: {
          bedroom: {
            toDo: null,
            quantity: 0,
            gamme: 1
          },
          bureau: {
            toDo: null,
            quantity: 0,
            gamme: 1
          },
          living: {
            toDo: null,
            gamme: 1,
            quantity: 0,
            size: null
          },
          diningRoom: {
            toDo: null,
            quantity: 0,
            gamme: 1,
            kitchenOpen: null
          }
        }
      },
      electricity: {
        renovation: {
          level: null
        },
        rooms: {
          bedroom: {
            toDo: null,
            quantity: 0,
            listOfElecEquipment: []
          },
          bathroom: {
            toDo: null,
            quantity: 0,
            listOfElecEquipment: []
          },
          wc: {
            toDo: null,
            quantity: 0,
            listOfElecEquipment: []
          },
          kitchen: {
            toDo: null,
            kitchenEquipment: [],
            listOfElecEquipment: []
          },
          living: {
            toDo: null,
            listOfElecEquipment: []
          },
          diningRoom: {
            toDo: null,
            listOfElecEquipment: []
          },
          luandry: {
            toDo: null,
            listOfElecEquipment: []
          },
          grenier: {
            toDo: null,
            listOfElecEquipment: []
          },
          bureau: {
            toDo: null,
            listOfElecEquipment: []
          },
          garage: {
            toDo: null,
            listOfElecEquipment: []
          },
          cellar: {
            toDo: null,
            listOfElecEquipment: []
          },
          gardenShed: {
            toDo: null,
            listOfElecEquipment: []
          }
        },
        supplements: {
          toDo: null
        },
        alarm: {
          toDo: null
        },
        exterior: {
          toDo: null,
          listOfElecEquipment: []
        },
        chargingPoint: {
          toDo: null
        },
        technic: {
          tableau: false,
          coffretGestionnaire: false,
          calbeCompteurTableau: false,
          priseTerre: false,
          forfaitMiseTerre: false,
          reception: false
        }
      }
    },
    initElec: {
      bedroom: {
        prise: 5,
        interrupteur: 2,
        pointLumineux: 1
      },
      bathroom: {
        prise: 4,
        interrupteur: 2,
        pointLumineux: 2
      },
      kitchen: {
        prise: 11,
        interrupteur: 3,
        pointLumineux: 2
      },
      wc: {
        prise: 1,
        interrupteur: 1,
        pointLumineux: 1
      },
      bureau: {
        prise: 6,
        interrupteur: 2,
        pointLumineux: 2
      },
      living: {
        prise: 10,
        interrupteur: 6,
        pointLumineux: 6
      },
      diningRoom: {
        prise: 10,
        interrupteur: 6,
        pointLumineux: 6
      },
      luandry: {
        prise: 4,
        interrupteur: 1,
        pointLumineux: 1
      },
      grenier: {
        prise: 1,
        interrupteur: 1,
        pointLumineux: 1
      },
      garage: {
        prise: 5,
        interrupteur: 2,
        pointLumineux: 4
      },
      cellar: {
        prise: 5,
        interrupteur: 4,
        pointLumineux: 4
      },
      gardenShed: {
        prise: 2,
        interrupteur: 1,
        pointLumineux: 1
      },
      exterior: {
        prise: 2,
        interrupteur: 1,
        pointLumineux: 1
      }
    },
    prices: {
      global: {
        demolition: {
          small: 300,
          middle: 500,
          big: 800
        }
      },
      electricity: {
        global: {
          margin: 1.25
        },
        equipment: {
          priseIn: 39,
          priseOut: 62.4,
          priseRj: 45.3,
          priseCoax: 74.55,
          interrupteurIn: 36.9,
          interrupteurOut: 65.55,
          pointLumineux: 25.35
        },
        technic: {
          tableau: 159,
          disjoncteurTete: 62.25,
          disjoncteurDiff63: 83.25,
          disjoncteurDiff40: 113.25,
          disjoncteur16: 30.75,
          disjoncteur25: 41.25,
          coffretGestionnaire: 139.5,
          cableCompteurTableau: 81,
          priseTerre: 112.5,
          forfaitMiseTerre: 300,
          reception: 350
        },
        alarm: 2100,
        borneVoiture: 500
      },
      airConditioning: {
        global: {
          margin: 1.3,
          hourlyRate: 46
        },
        unitIn: {
          topQuality: {
            low: 330,
            middle: 380,
            high: 550
          },
          standard: {
            low: 215,
            middle: 256,
            high: 380
          }
        },
        unitOut: 230,
        accessories: {
          ligneFrigorifique: 250,
          condensat: 20,
          cablageUnitIn: 30,
          alimElecExt: 200
        },
        options: {
          commandeCablee: 150,
          alimentationUnitOut: 200
        }
      },
      bathroom: {
        global: {
          margin: 1.25,
          supplierDiscount: 0.35
        },
        finition: {
          ground: {
            carrelage: {
              standard: {
                flatRate: 70
              },
              top: {
                flatRate: 110
              }
              
            },
            plinthe: { 
              flatRate: 25
            },
            plancher: { 
              flateRate: 70
            }
          },
          wall: {
            faience: {
              standard: {
                flatRate: 70
              },
              top: {
                flatRate: 110
              }
            },
            panneau: {
              flatRate: 52,
              equipment: 88.14
            }
          },
          ceiling: {
            plaques: 81.25,
            spots: 75
          },
          extra: {
            wallPatching: {
              douche: 300,
              underTen: 200,
              underTwenty: 300,
              underThirty: 400,
              thirtyAndMore: 475
            },
            screed: {
              underFive: 250,
              underSeven: 400,
              underNine: 475,
              underFifteen: 650
            }
          }
        },
        conception: {
          wc: {
            suspendu: {
              flatRate: 400,
              equipment: {
                 standard: 668.27,
                 top: 837.69
              }
            },
            surPied: {
              flatRate: 175,
              equipment: {
                standard: 385,
                top: 695
             }
            }
          },
          sink: {
            equipments: {
              vidange: {
                flatRate: 30,
                equipment: 53
              },
              siphon: {
                flatRate: 30,
                equipment: 68
              },
              prise: {
                flatRate: 275
              }
            },            
            lavabo: {
              simple: {
                standard: {
                  flatRate: 125,
                  equipment: 184
                },
                top: {
                  flatRate: 125,
                  equipment: 259
                }
              },
              double: { 
                standard: {
                  flatRate: 250,
                  equipment: 368
                },
                top: {
                  flatRate: 250,
                  equipment: 518
                }
              }
            },
            vasque: {
              simple: {
                standard: {
                  flatRate: 170,
                  equipment: 476
                },
                top: {
                  flatRate: 170,
                  equipment: 1310
                }
              },
              double: {
                standard: {
                  flatRate: 340,
                  equipment: 912
                },
                top: {
                  flatRate: 340,
                  equipment: 1606
                }
              }
            },
            mixer: {
              flatRate: 40,
              lavabo: {
                equipment: {
                  standard: 122.5,
                  top: 240
                }
              },
              vasque: {
                equipment: 240
              }
            },
            miror: {
              flatRate: 40,
              simple: {
                equipment: 50
              },
              double: {
                equipment: 179
              }
            }
          },
          baignoire: {
            flatRate: 175,
            equipment: {
              standard: 474,
              top: 642
            },
            mixer: {
              standard: {
                flatRate: 40,
                equipment: 320.5
              },
              top: {
                flatRate: 70,
                equipment: 449
              }
            }
          },
          douche: {
            cabin: {
              flatRate: 650,
              equipment: 1959
            },
            italian: {
              flatRate: 1025
            },
            tub: {
              flatRate: 300,
              equipment: {
                standard: 594,
                top: 892
              }
            },
            mixer: {
              standard: {
                flatRate: 40,
                equipment: 278.5
              },
              top: {
                flatRate: 70,
                equipment: 545
              }
            },
            paroi: {
              simple: {
                flatRate: 185,
                equipment: {
                  standard: 564,
                  top: 1078
                }
              },
              double: {
                flatRate: 185,
                equipment: 1267.73
              }
            },
            wall: {
              faience: {
                flatRate: {
                  standard: 441,
                  top: 625.8
                }
              },
              panneau: {
                flatRate: 300,
                equipment: 678
              }
            }
          }
        },
        plumbing: {
          flatRate: 150,
          pointOfWater: 200
        }
      }
    }
  },
  getters: {
    typeOfConstruction(state) {
      if(state.quote.basisInformations.dateOfConstruction === 0) {
        return 'Construction neuve';
      } else if(state.quote.basisInformations.dateOfConstruction === 1) {
        return 'Moins de 10 ans';
      } else {
        return '10 ans ou plus';
      }
    },
    typeOfDemolition(state) {
      if(state.quote.bathroom.preparation.demolition.type === "small") {
        return { 
          name: "Petite démolition",
          description: "Pas de démolition ou très peu.",
          price: state.prices.global.demolition.small
        };
      } else if (state.quote.bathroom.preparation.demolition.type === "middle") {
        return { 
          name: "Moyenne démolition",
          description: "Détapisser, enlever un revêtement léger ...",
          price: state.prices.global.demolition.middle
        };
      } else if (state.quote.bathroom.preparation.demolition.type === "big") {
        return { 
          name: "Grosse démolition",
          description: "Retirer du carrelage, démonter les sanitaires, décloisonner, retirer l'électricité ...",
          price: state.prices.global.demolition.big
        };
      }
      else {
        return { 
          name: "",
          description: "",
          price: 0
        };
      }
    },
    areaCalc(state) {
      return state.quote.roomInformations.width * state.quote.roomInformations.length;
    },
    areaGroundCalc(state, getters) {
      let toSoustract = 0;
      if(state.quote.bathroom.conception.douche.toDo && state.quote.bathroom.conception.douche.type !== "cabine") {
        toSoustract += 1.2;
      }
      if(state.quote.bathroom.conception.baignoire.toDo) {
        toSoustract += 1.7;
      }
      return getters.areaCalc - toSoustract;
    },
    perimeterCalc(state) {
      return (parseInt(state.quote.roomInformations.width) + parseInt(state.quote.roomInformations.length)) * 2;
    },
    wallAreaCalc(state, getters) {
      let wallArea =  getters.perimeterCalc * 2.4;
      if(state.quote.bathroom.conception.douche.toDo && state.quote.bathroom.conception.douche.type !== "cabine") {
        wallArea -= 7;
      }
      return parseFloat(wallArea);
    },
    wetWallAreaCalc(state) {
      let wetWall = 0;
      if(state.quote.bathroom.conception.baignoire.quantity > 0) {
        wetWall += 4;
      }
      if(state.quote.bathroom.conception.sink.vasque.quantity > 0) {
        wetWall += 2;
      }
      if(state.quote.bathroom.conception.wc.quantity > 0 && state.quote.bathroom.conception.wc.type === "suspendu") {
        wetWall += 2;
      }
      return parseInt(wetWall);
    },
    getAirConditioningMargin(state) {
      return parseFloat(state.prices.airConditioning.global.margin);
    },
    priceOfUnitInBedroom(state, getters) {
      if(state.quote.airConditioning.rooms.bedroom.toDo) {
        if(state.quote.airConditioning.rooms.bedroom.gamme === 1) {
          const price = state.prices.airConditioning.unitIn.standard.low * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
        } else if(state.quote.airConditioning.rooms.bedroom.gamme === 2) {
          const price = state.prices.airConditioning.unitIn.topQuality.low * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    totalPriceOfUnitInBedroom(state, getters) {
      return state.quote.airConditioning.rooms.bedroom.quantity * getters.priceOfUnitInBedroom;
    },
    totalPriceOfUnitInBureau(state, getters) {
      if(state.quote.airConditioning.rooms.bureau.quantity) {
        if(state.quote.airConditioning.rooms.bureau.gamme === 1) {
          const price = state.prices.airConditioning.unitIn.standard.low * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
        } else if(state.quote.airConditioning.rooms.bureau.gamme === 2) {
          const price = state.prices.airConditioning.unitIn.topQuality.low * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    totalPriceOfUnitInDiningRoom(state, getters) {
      if(state.quote.airConditioning.rooms.diningRoom.quantity) {
        if(state.quote.airConditioning.rooms.diningRoom.gamme === 1) {
          if(!state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
            const price = state.prices.airConditioning.unitIn.standard.middle * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
          } else if(state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
            const price = state.prices.airConditioning.unitIn.standard.high * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
          } else {
            return 0;
          }  
          
        } else if(state.quote.airConditioning.rooms.diningRoom.gamme === 2) {
          if(!state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
            const price = state.prices.airConditioning.unitIn.topQuality.middle * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
          } else if(state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
            const price = state.prices.airConditioning.unitIn.topQuality.high * getters.getAirConditioningMargin;
          const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
          const totalPrice = price + workforce;
          return parseFloat(totalPrice);
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    totalPriceOfUnitInLiving(state, getters) {
      if(state.quote.airConditioning.rooms.living.quantity) {
        if(state.quote.airConditioning.rooms.living.gamme === 1) {
          if(state.quote.airConditioning.rooms.living.size === 'small') {
            const price = state.prices.airConditioning.unitIn.standard.middle * getters.getAirConditioningMargin;
            const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
            const totalPrice = price + workforce;
            return parseFloat(totalPrice);
          } else if(state.quote.airConditioning.rooms.living.size === 'big') {
            const price = state.prices.airConditioning.unitIn.standard.high * getters.getAirConditioningMargin;
            const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
            const totalPrice = price + workforce;
            return parseFloat(totalPrice);
          } else {
            return 0;
          }  
        } else if(state.quote.airConditioning.rooms.living.gamme === 2) {
          if(state.quote.airConditioning.rooms.living.size === 'small') {
            const price = state.prices.airConditioning.unitIn.topQuality.middle * getters.getAirConditioningMargin;
            const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
            const totalPrice = price + workforce;
            return parseFloat(totalPrice);
          } else if(state.quote.airConditioning.rooms.living.size === 'big') {
            const price = state.prices.airConditioning.unitIn.topQuality.high * getters.getAirConditioningMargin;
            const workforce = 4 * state.prices.airConditioning.global.hourlyRate;
            const totalPrice = price + workforce;
            return parseFloat(totalPrice);
          } else {
            return 0;
          }  
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    totalOfLowWatts(state) {
      const wattsOfBureau = state.quote.airConditioning.rooms.bureau.quantity ? 1 : 0;
      return parseFloat(2.5 * (parseInt(state.quote.airConditioning.rooms.bedroom.quantity) + parseInt(wattsOfBureau)));
    },
    totalOfMiddleWatts(state) {
      let wattsOfLiving = 0;
      let wattsOfDiningRoom = 0;
      if(state.quote.airConditioning.rooms.living.size === 'small') {
        wattsOfLiving = state.quote.airConditioning.rooms.living.quantity ? 1 : 0;
      } 
      if(!state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
        wattsOfDiningRoom = state.quote.airConditioning.rooms.diningRoom.quantity ? 1 : 0;
      }
      return parseFloat(3.5 * (parseInt(wattsOfLiving) + parseInt(wattsOfDiningRoom)));
    },
    totalOfHighWatts(state) {
      let wattsOfLiving = 0;
      let wattsOfDiningRoom = 0;
      if(state.quote.airConditioning.rooms.living.size === 'big') {
        wattsOfLiving = state.quote.airConditioning.rooms.living.quantity ? 1 : 0;
      } 
      if(state.quote.airConditioning.rooms.diningRoom.kitchenOpen) {
        wattsOfDiningRoom = state.quote.airConditioning.rooms.diningRoom.quantity ? 1 : 0;
      }
      return parseFloat(5 * (parseInt(wattsOfLiving) + parseInt(wattsOfDiningRoom)));
    },
    totalOfWatts(state, getters) {
      return parseFloat(getters.totalOfLowWatts + getters.totalOfMiddleWatts + getters.totalOfHighWatts);
    },
    totalOfUnitsOut(state) {
      const unitOutBedroom = state.quote.airConditioning.rooms.bedroom.quantity;
      const unitOutBureau = state.quote.airConditioning.rooms.bureau.quantity ? 1 : 0;
      const unitOutLiving = state.quote.airConditioning.rooms.living.quantity ? 1 : 0;
      const unitOutDiningRoom = state.quote.airConditioning.rooms.diningRoom.quantity ? 1 : 0;

      const totalOfUnitsOut = parseInt(unitOutBedroom) + parseInt(unitOutBureau) + parseInt(unitOutLiving) + parseInt(unitOutDiningRoom);
      return totalOfUnitsOut;
    },
    totalPriceOfUnitOut(state, getters) {
      if(getters.totalOfWatts > 0) {
        return parseFloat((getters.totalOfWatts * state.prices.airConditioning.unitOut * state.prices.airConditioning.global.margin) + (state.prices.airConditioning.global.hourlyRate * (5 + (2 * getters.totalOfUnitsOut))));
      } else {
        return 0;
      }
    },
    totalPriceOfLignesFrigorifiques(state, getters) {
      if(getters.totalOfWatts > 0) {
        return parseFloat(getters.totalOfUnitsOut * ((state.prices.airConditioning.accessories.ligneFrigorifique * state.prices.airConditioning.global.margin) + (4 * state.prices.airConditioning.global.hourlyRate)));
      } else {
        return 0;
      }
    },
    totalPriceOfCondensat(state, getters) {
      if(getters.totalOfWatts > 0) {
        return parseFloat(getters.totalOfUnitsOut * ((state.prices.airConditioning.accessories.condensat * state.prices.airConditioning.global.margin) + (2 * state.prices.airConditioning.global.hourlyRate)));
      } else {
        return 0;
      }
    },
    totalPriceOfCablageUnitIn(state, getters) {
      if(getters.totalOfWatts > 0) {
        return parseFloat(getters.totalOfUnitsOut * ((state.prices.airConditioning.accessories.cablageUnitIn * state.prices.airConditioning.global.margin) + (2 * state.prices.airConditioning.global.hourlyRate)));
      } else {
        return 0;
      }
    },
    totalPriceOfAlimElecExt(state) {
      if(state.quote.airConditioning.options.alimElecExt) {
        return state.prices.airConditioning.accessories.alimElecExt + (2 * state.prices.airConditioning.global.hourlyRate);
      } else {
        return 0;
      }
    },
    getBathroomMargin(state) {
      return parseFloat(state.prices.bathroom.global.margin);
    },
    getBathroomSupplierDiscount(state) {
      return parseFloat(1 - state.prices.bathroom.global.supplierDiscount);
    },
    typeOfDouche(state) {
      if(state.quote.bathroom.conception.douche.type === "cabine") {
        return { 
          name: "Douche - Type 'cabine'",
          description: "Fourniture et pose d'une cabine de douche Kinedo Eden de 90x120 cm Ht:201cm, Portes coulissantes de 5mm d'épaisseur et mitigeur thermostatique inclus."
        };
      } else if(state.quote.bathroom.conception.douche.type === "italienne") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          return { 
            name: "Douche - Type 'à l'italienne'",
            description: "Douche de plain pied, avec kit d'étanchéité et bonde d'évacuation. Finition carrelage antidérapant au choix du client. Colle et joints Epoxy. Comprend le mitigeur Grohe GROTHERM1000."
          };
        } else {
          return { 
            name: "Douche - Type 'à l'italienne'",
            description: "Douche de plain pied, avec kit d'étanchéité et bonde d'évacuation. Finition carrelage antidérapant au choix du client. Colle et joints Epoxy. Comprend le mitigeur douche pluie Grohe Tempesta Cosmo 210."
          };
        }
        
      } else if(state.quote.bathroom.conception.douche.type === "receveur") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          return { 
            name: "Douche - Type 'receveur'",
            description: "Fourniture et pose d'un Tub de douche de 120x90cm Huppe Easy step épaisseur 4cm + bonde d'évacuation. Comprend le mitigeur Grohe GROTHERM1000."
          };
        } else {
          return { 
            name: "Douche - Type 'receveur'",
            description: "Fourniture et pose d'un Tub de douche de 120x90cm Villeroy et Boch Futurion Flat Tub + bonde d'évacuation. Comprend le mitigeur douche pluie Grohe Tempesta Cosmo 210."
          };
        }
      }
      else {
        return {
          name: "",
          description: ""
        }
      }
    },
    priceOfDouche(state, getters) {
      if(state.quote.bathroom.conception.douche.type === "cabine") {
        const price = (state.prices.bathroom.conception.douche.cabin.flatRate + (state.prices.bathroom.conception.douche.cabin.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else if(state.quote.bathroom.conception.douche.type === "italienne") {
        const price = state.prices.bathroom.conception.douche.italian.flatRate * getters.getBathroomMargin;
        return parseFloat(price);
      } else if(state.quote.bathroom.conception.douche.type === "receveur") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          const price = (state.prices.bathroom.conception.douche.tub.flatRate + (state.prices.bathroom.conception.douche.tub.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.douche.tub.flatRate + (state.prices.bathroom.conception.douche.tub.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
        
      } else {
        return 0
      }
    },
    priceOfMixerDouche(state, getters) {
      if(state.quote.bathroom.conception.douche.type === "cabine") {
        return 0;
      } else {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          const price = (state.prices.bathroom.conception.douche.mixer.standard.flatRate + (state.prices.bathroom.conception.douche.mixer.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.douche.mixer.top.flatRate + (state.prices.bathroom.conception.douche.mixer.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
      }
    },
    typeOfParoiDouche(state) {
      if(state.quote.bathroom.conception.douche.paroi.type === "simple") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          return { 
            name: "Paroi simple",
            description: "Fourniture et pose d'une paroi de douche fixe Inda Walk-In de 120x200 cm."
          };
        } else {
          return { 
            name: "Paroi simple",
            description: "Fourniture et pose d'une paroi de douche fixe Huppe Xtensa Walk-In coulisant 120x200cm."
          };
        }
      } else if(state.quote.bathroom.conception.douche.paroi.type === "double") {
          return { 
            name: "Paroi double",
            description: "Fourniture et pose d'une double paroi en L avc une partie fixe et une partie pivotante."
          };
      } else {
        return {
          name: "",
          description: ""
        }
      }
    },
    priceOfParoiDouche(state, getters) {
      if(state.quote.bathroom.conception.douche.paroi.type === "simple") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          const price = (state.prices.bathroom.conception.douche.paroi.simple.flatRate + (state.prices.bathroom.conception.douche.paroi.simple.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.douche.paroi.simple.flatRate + (state.prices.bathroom.conception.douche.paroi.simple.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
      } else if(state.quote.bathroom.conception.douche.paroi.type === "double") {
        const price = (state.prices.bathroom.conception.douche.paroi.double.flatRate + (state.prices.bathroom.conception.douche.paroi.double.equipment * getters.getBathroomSupplierDiscount * state.quote.bathroom.conception.douche.paroi.gamme)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else {
        return 0
      }
    },
    typeOfWallDouche(state) {
      if(state.quote.bathroom.conception.douche.wall === "faience") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          return { 
            name: "Mur en faïence",
            description: "Ragréage des murs douche de 120+90 cm sur une hauteur de 240cm, achat et pose d'un carrelage à 40€/m² au choix du client."
          };
        } else {
          return { 
            name: "Mur en faïence",
            description: "Ragréage des murs douche de 120+90 cm sur une hauteur de 240cm, achat et pose d'un carrelage à 70€/m² au choix du client."
          };
        }
      } else if(state.quote.bathroom.conception.douche.wall === "panneau") {
          return { 
            name: "Panneaux muraux",
            description: "Fourniture et pose de panneaux étanches au niveau de la douche."
          };
      } else {
        return {
          name: "",
          description: ""
        }
      }
    },
    priceOfPatchingWallDouche(state, getters) {
      if(state.quote.bathroom.conception.douche.wall === "faience") {
        const price = state.prices.bathroom.finition.extra.wallPatching.douche  * getters.getBathroomMargin;
        return parseFloat(price);
      } else {
        return 0;
      }
    },
    priceOfFinitionWallDouche(state, getters) {
      if(state.quote.bathroom.conception.douche.wall === "faience") {
        if(state.quote.bathroom.conception.douche.gamme === 1) {
          const price = (state.prices.bathroom.conception.douche.wall.faience.flatRate.standard * getters.getBathroomMargin) + getters.priceOfPatchingWallDouche;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.douche.wall.faience.flatRate.top * getters.getBathroomMargin) + getters.priceOfPatchingWallDouche;
          return parseFloat(price);
        }
        
      } else if(state.quote.bathroom.conception.douche.wall === "panneau") {
        const price = (state.prices.bathroom.conception.douche.wall.panneau.flatRate + (state.prices.bathroom.conception.douche.wall.panneau.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else {
        return 0
      }
    },
    priceOfDoucheSet(state, getters) {
      const price = getters.priceOfDouche + getters.priceOfMixerDouche + getters.priceOfParoiDouche + getters.priceOfFinitionWallDouche + getters.priceOfPatchingWallDouche;
      return parseFloat(price);
    },
    totalPriceOfDoucheSet(state, getters) {
      return state.quote.bathroom.conception.douche.quantity * getters.priceOfDoucheSet;
    },
    typeOfBaignoire(state) {
      if(state.quote.bathroom.conception.baignoire.toDo) {
        if(state.quote.bathroom.conception.baignoire.gamme === 1) {
          return { 
            name: "Baignoire",
            description: "Fourniture et pose d'une baignoire Dcode Duravit de 170*75cm avec pieds et tablier. Comprend le mitigeur Grohe GROTHERM1000."
          };
        } else {
          return { 
            name: "Baignoire",
            description: "Fourniture et pose d'une baignoire Onovo bain Duo Villeroy et Boch de 180*80cm avec pieds et tablier. Comprend le mitigeur Grohe GROTHERM2000."
          };
        }
        
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    priceOfBaignoire(state, getters) {
      if(state.quote.bathroom.conception.baignoire.gamme === 1) {
        const price = (state.prices.bathroom.conception.baignoire.flatRate + (state.prices.bathroom.conception.baignoire.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else {
        const price = (state.prices.bathroom.conception.baignoire.flatRate + (state.prices.bathroom.conception.baignoire.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      }
    },
    priceOfMixerBaignoire(state, getters) {
      if(state.quote.bathroom.conception.baignoire.gamme === 1) {
        const price = (state.prices.bathroom.conception.baignoire.mixer.standard.flatRate + (state.prices.bathroom.conception.baignoire.mixer.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else {
        const price = (state.prices.bathroom.conception.baignoire.mixer.top.flatRate + (state.prices.bathroom.conception.baignoire.mixer.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      }
    },
    priceOfBaignoireSet(state, getters) {
      const price = getters.priceOfBaignoire + getters.priceOfMixerBaignoire;
      return parseFloat(price);
    },
    totalPriceOfBaignoireSet(state, getters) {
      return state.quote.bathroom.conception.baignoire.quantity * getters.priceOfBaignoireSet;
    },
    typeOfVasque(state) {
      if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
        if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
          if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
            return { 
              name: "Simple vasque",
              description: "Fourniture et pose d'une vasque Pix'l table en marbre de synthèse et meuble sous vasque Pix'L de 70cm avec deux portes Coloris Blanc. Vidage, Siphon et mitigeur lavabo, miroir, prise et réglette à spots."
            };
          } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
            return { 
              name: "Simple lavabo",
              description: "Fourniture et pose d'un lavabo Starck 3 Duravit de 65cm avec trop plein, vidage, siphon, mitigeur lavabo, miroir, prise et réglette à spots."
            };
          }
        } else {
          if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
            return { 
              name: "Simple vasque",
              description: "Fourniture et pose d'une vasque Pix'l table en marbre de synthèse et meuble sous vasque Pix'L de 70cm avec deux portes Coloris Blanc. Avec colonne Pix'L et meuble miroir Pix'L Vidage, Siphon, mitigeur vasque, prise et réglette à spots."
            };
          } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
            return { 
              name: "Simple lavabo",
              description: "Fourniture et pose d'un lavabo Subway 2.0 60x47cm de Villeroy et Boch 65cm avec trop plein, vidage, siphon, mitigeur lavabo, miroir, prise et réglette à spots."
            };
          }
        }
        
      } else if(state.quote.bathroom.conception.sink.vasque.quantity === 2) {
        if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
          if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
            return { 
              name: "Double vasque",
              description: "Fourniture et pose d'une vasque Pix'l table en marbre de synthèse et meuble sous vasque Pix'L de 120cm avec deux tioirs Coloris Blanc. Siphon, vidage, mitigeurs vasque, miroir 120x60 cm, deux prises et deux réglettes à spots."
            };
          } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
            return { 
              name: "Double lavabo",
              description: "Fourniture et pose de deux lavabos Starck 3 Duravit de 65cm avec trop plein, vidages, siphons, mitigeurs lavabo et miroir 120*60, deux et prises et deux réglettes à spots."
            };
          }
        } else {
          if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
            return { 
              name: "Double vasque",
              description: "Fourniture et pose d'une vasque Pix'l table en marbre de synthèse et meuble sous vasque Pix'L de 120cm avec deux tioirs Coloris Blanc. Colonne Pix'L comprises Siphon, vidage, mitigeurs vasque, mioir 120x60 cm, deux prises et deux réglettes à spots."
            };
          } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
            return { 
              name: "Double lavabo",
              description: "Fourniture et pose de deux lavabos Subway 2.0 60x47cm de Villeroy et Boch de 65cm avec trop plein, vidages, siphons, mitigeurs lavabo et miroir 120*60, deux et prises et deux réglettes à spots."
            };
          }
        }
      } else {
        return {
          name: "",
          description: ""
        }
      }
    },
    priceOfVasque(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
        if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
          if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
            const price = (state.prices.bathroom.conception.sink.vasque.simple.standard.flatRate + (state.prices.bathroom.conception.sink.vasque.simple.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          } else {
            const price = (state.prices.bathroom.conception.sink.vasque.simple.top.flatRate + (state.prices.bathroom.conception.sink.vasque.simple.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          }
        } else {
          if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
            const price = (state.prices.bathroom.conception.sink.vasque.double.standard.flatRate + (state.prices.bathroom.conception.sink.vasque.double.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          } else {
            const price = (state.prices.bathroom.conception.sink.vasque.double.top.flatRate + (state.prices.bathroom.conception.sink.vasque.double.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          }
        }
        
      } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
        if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
          if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
            const price = (state.prices.bathroom.conception.sink.lavabo.simple.standard.flatRate + (state.prices.bathroom.conception.sink.lavabo.simple.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          } else {
            const price = (state.prices.bathroom.conception.sink.lavabo.simple.top.flatRate + (state.prices.bathroom.conception.sink.lavabo.simple.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          }
        } else {
          if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
            const price = (state.prices.bathroom.conception.sink.lavabo.double.standard.flatRate + (state.prices.bathroom.conception.sink.lavabo.double.standard.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          } else {
            const price = (state.prices.bathroom.conception.sink.lavabo.double.top.flatRate + (state.prices.bathroom.conception.sink.lavabo.double.top.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
            return parseFloat(price);
          }
        }
      } else {
        return 0;
      }
    },
    typeOfMiror(state) {
      if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
        return { 
          name: "Miroir simple",
          description: "Dimensions : 80x60cm"
        };
      } else if(state.quote.bathroom.conception.sink.vasque.quantity === 2) {
        return { 
          name: "Miroir double",
          description: "Dimensions : 120x65cm"
        };
      } else {
        return 0;
      }
    },
    priceOfPrise(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.quantity > 0) {
        const prise = state.prices.bathroom.conception.sink.equipments.prise.flatRate * getters.getBathroomMargin;
        return parseFloat(prise);
      } else {
        return 0;
      }
    },
    priceOfMiror(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
        const price = (state.prices.bathroom.conception.sink.miror.flatRate + (state.prices.bathroom.conception.sink.miror.simple.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin + getters.priceOfPrise;
        return parseFloat(price);
      } else if(state.quote.bathroom.conception.sink.vasque.quantity === 2) {
        const price = (state.prices.bathroom.conception.sink.miror.flatRate + (state.prices.bathroom.conception.sink.miror.double.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin + 2 * getters.priceOfPrise;
        return parseFloat(price);
      } else {
        return 0;
      }
    },
    typeOfMixerSink(state) {
      if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
        return { 
          name: "Mitigeur pour vasque",
          description: "Grohe Eurocube"
        };
      } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
        return { 
          name: "Grohe Eurostyle Cosmopolitan",
          description: "Systemfix Geberit avec cuvette Duravit"
        };
      } else {
        return 0;
      }
    },
    priceOfMixerSink(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.type === "vasque") {
        const price = (state.prices.bathroom.conception.sink.mixer.flatRate + (state.prices.bathroom.conception.sink.mixer.vasque.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        return parseFloat(price);
      } else if(state.quote.bathroom.conception.sink.vasque.type === "lavabo") {
        if(state.quote.bathroom.conception.sink.vasque.gamme === 1) {
          const price = (state.prices.bathroom.conception.sink.mixer.flatRate + (state.prices.bathroom.conception.sink.mixer.lavabo.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.sink.mixer.flatRate + (state.prices.bathroom.conception.sink.mixer.lavabo.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
        
      } else {
        return 0;
      }
    },
    priceOfEquipments(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.quantity > 0) {
        const vidange = (state.prices.bathroom.conception.sink.equipments.vidange.flatRate + (state.prices.bathroom.conception.sink.equipments.vidange.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        const siphon = (state.prices.bathroom.conception.sink.equipments.siphon.flatRate + (state.prices.bathroom.conception.sink.equipments.siphon.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
        const price = vidange + siphon;
        return parseFloat(price);
      } else {
        return 0;
      }
    },
    priceOfSinkSet(state, getters) {
      if(state.quote.bathroom.conception.sink.vasque.quantity === 1) {
        const price = getters.priceOfVasque + getters.priceOfMixerSink + getters.priceOfEquipments;
        return parseFloat(price);
      } else {
        const price = getters.priceOfVasque + 2 * getters.priceOfMixerSink + 2 * getters.priceOfEquipments;
        return parseFloat(price);
      }
      
    },
    totalPriceOfSinkSet(state, getters) {
      return getters.priceOfSinkSet + getters.priceOfMiror;
    },
    typeOfWc(state) {
      if(state.quote.bathroom.conception.wc.type === "suspendu") {
        if(state.quote.bathroom.conception.wc.gamme === 1) {
          return { 
            name: "WC suspendu",
            description: "Fourniture et pose d'un wc suspendu System Fix Geberit avec cuvette Duravit Dcode et plaque de contrôle deux boutons."
          };
        } else {
          return { 
            name: "WC suspendu",
            description: "Fourniture et pose d'un wc suspendu System Fix Geberrit avec cuvette Starck 3 Compact avec siège softclosing."
          };
        }
        
      } else if(state.quote.bathroom.conception.wc.type === "surPied") {
        if(state.quote.bathroom.conception.wc.gamme === 1) {
          return { 
            name: "WC sur pied",
            description: "Fourniture et pose d'un wc sur pied type Combi pack O'novo."
          };
        } else {
          return { 
            name: "WC sur pied",
            description: "Fourniture et pose d'un wc sur pied Durastyle cuvette sol avec siège softclosing."
          };
        }
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    priceOfWc(state, getters) {
      if(state.quote.bathroom.conception.wc.type === "suspendu") {
        if(state.quote.bathroom.conception.wc.gamme === 1) {
          const price = (state.prices.bathroom.conception.wc.suspendu.flatRate + (state.prices.bathroom.conception.wc.suspendu.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.wc.suspendu.flatRate + (state.prices.bathroom.conception.wc.suspendu.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
      } else if(state.quote.bathroom.conception.wc.type === "surPied") {
        if(state.quote.bathroom.conception.wc.gamme === 1) {
          const price = (state.prices.bathroom.conception.wc.surPied.flatRate + (state.prices.bathroom.conception.wc.surPied.equipment.standard * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        } else {
          const price = (state.prices.bathroom.conception.wc.surPied.flatRate + (state.prices.bathroom.conception.wc.surPied.equipment.top * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
          return parseFloat(price);
        }
        
      } else {
        return 0
      }
    },
    typeOfGround(state) {
      if(state.quote.bathroom.finitions.ground.type === "carrelage") {
        if(state.quote.bathroom.finitions.ground.gamme === 1) {
          return { 
            name: "Carrelage",
            description: "Achat et pose d'un carrelage de sol d'une valeur de 40€/m² au choix du client. Plinthes comprises."
          };
        } else {
          return { 
            name: "Carrelage",
            description: "Achat et pose d'un carrelage de sol d'une valeur de 70€/m² au choix du client. Plinthes comprises."
          };
        }
        
      } else if(state.quote.bathroom.finitions.ground.type === "plancher") {
        return { 
          name: "Plancher flottant",
          description: "Achat et pose d'un revêtement sol type quickstep pour pièce humide. Coloris au choix du client. Plinthes comprises."
        };
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    typeOfGroundScreed(state, getters) {
      if(getters.areaCalc <= 5) {
        return { 
          name: "Chape égale ou inférieure à 5m²",
          description: ""
        };
      } else if(getters.areaCalc <= 7) {
        return { 
          name: "Chape égale ou inférieure à 7m²",
          description: ""
        };
      } else if(getters.areaCalc <= 9) {
        return { 
          name: "Chape égale ou inférieure à 9m²",
          description: ""
        };
      } else if(getters.areaCalc <= 15) {
        return { 
          name: "Chape égale ou inférieure à 15m²",
          description: ""
        };
      } else if(getters.areaCalc > 15) {
        return { 
          name: "Chape supérieure à 15m²",
          description: ""
        };
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    flatRateOfGroundScreed(state, getters) {
      if(state.quote.bathroom.finitions.ground.toDo && state.quote.bathroom.preparation.demolition.type === "big") {
        if(getters.areaCalc <= 5) {
          return state.prices.bathroom.finition.extra.screed.underFive * getters.getBathroomMargin;
        } else if(getters.areaCalc <= 7) {
          return state.prices.bathroom.finition.extra.screed.underSeven * getters.getBathroomMargin;
        } else if(getters.areaCalc <= 9) {
          return state.prices.bathroom.finition.extra.screed.underNine * getters.getBathroomMargin;
        } else if(getters.areaCalc <= 15) {
          return state.prices.bathroom.finition.extra.screed.underFifteen * getters.getBathroomMargin;
        } else {
          return state.prices.bathroom.finition.extra.screed.underFifteen * getters.getBathroomMargin;
        }
      } else {
        return 0;
      }
    },
    priceOfGroundType(state, getters) {
      if(state.quote.bathroom.finitions.ground.type === "carrelage") {
        if(state.quote.bathroom.finitions.ground.gamme === 1) {
          return state.prices.bathroom.finition.ground.carrelage.standard.flatRate * getters.getBathroomMargin;
        } else {
          return state.prices.bathroom.finition.ground.carrelage.top.flatRate * getters.getBathroomMargin;
        }
        
      } else if(state.quote.bathroom.finitions.ground.type === "plancher") {
        return state.prices.bathroom.finition.ground.plancher.flateRate * getters.getBathroomMargin;
      } else {
        return 0;
      }
    },
    totalPriceOfGroundType(state, getters) {
      return getters.priceOfGroundType * getters.areaGroundCalc;
    },
    priceOfPlinthe(state, getters) {
      if(state.quote.bathroom.finitions.ground.type === "carrelage") {
        return state.prices.bathroom.finition.ground.plinthe.flatRate * getters.getBathroomMargin;
      } else {
        return 0;
      }
    },
    totalPriceOfPlinthe(state, getters) {
      if(state.quote.bathroom.finitions.ground.type === "carrelage") {
        return getters.priceOfPlinthe * getters.perimeterCalc;
      } else {
        return 0;
      }
    },
    totalPriceOfGround(state, getters) {
      return getters.totalPriceOfGroundType + getters.flatRateOfGroundScreed + getters.totalPriceOfPlinthe;
    },
    typeOfWall(state) {
      if(state.quote.bathroom.finitions.wall.type === "faience") {
        if(state.quote.bathroom.finitions.wall.gamme === 1) {
          return { 
            name: "Faïence",
            description: "Achat et pose d'un carrelage mural d'une valeur de 40€/m² au choix du client."
          };
        } else {
          return { 
            name: "Faïence",
            description: "Achat et pose d'un carrelage mural d'une valeur de 70€/m² au choix du client."
          };
        }
        
      } else if(state.quote.bathroom.finitions.wall.type === "panneau") {
        return { 
          name: "Panneaux muraux",
          description: "Achat et pose de panneaux muraux AKW, coloris au choix du client."
        };
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    typeOfWallArea(state) {
      if(state.quote.bathroom.finitions.wall.zones === "allWall") {
        return { 
          name: "Tous les murs",
          description: ""
        };
      } else if(state.quote.bathroom.finitions.wall.zones === "wetWall") {
        return { 
          name: "Zones humides uniquement",
          description: ""
        };
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    typeOfWallScreed(state, getters) {
      if(getters.wallAreaCalc < 10) {
        return { 
          name: "Ragréage de moins de 10m²",
          description: ""
        };
      } else if(getters.wallAreaCalc < 20) {
        return { 
          name: "Ragréage de moins de 20m²",
          description: ""
        };
      } else if(getters.wallAreaCalc < 30) {
        return { 
          name: "Ragréage de moins de 30m²",
          description: ""
        };
      } else if(getters.wallAreaCalc >= 30) {
        return { 
          name: "Ragréage égal ou supérieur à 30m²",
          description: ""
        };
      } else {
        return { 
          name: "",
          description: ""
        };
      }
    },
    flatRateOfWallScreed(state, getters) {
      if(state.quote.bathroom.finitions.wall.toDo && state.quote.bathroom.finitions.wall.type === "faience") {
        if(getters.wallAreaCalc <= 10) {
          return state.prices.bathroom.finition.extra.wallPatching.underTen * getters.getBathroomMargin;
        } else if(getters.wallAreaCalc <= 20) {
          return state.prices.bathroom.finition.extra.wallPatching.underTwenty * getters.getBathroomMargin;
        } else if(getters.wallAreaCalc <= 30) {
          return state.prices.bathroom.finition.extra.wallPatching.underThirty * getters.getBathroomMargin;
        } else if(getters.wallAreaCalc > 30) {
          return state.prices.bathroom.finition.extra.wallPatching.thirtyAndMore * getters.getBathroomMargin;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    priceOfWallType(state, getters) {
      if(state.quote.bathroom.finitions.wall.type === "faience") {
        if(state.quote.bathroom.finitions.wall.gamme === 1) {
          return state.prices.bathroom.finition.wall.faience.standard.flatRate * getters.getBathroomMargin;
        } else {
          return state.prices.bathroom.finition.wall.faience.top.flatRate * getters.getBathroomMargin;
        }
      } else if(state.quote.bathroom.finitions.wall.type === "panneau") {
        return (state.prices.bathroom.finition.wall.panneau.flatRate + (state.prices.bathroom.finition.wall.panneau.equipment * getters.getBathroomSupplierDiscount)) * getters.getBathroomMargin;
      } else {
        return 0;
      }
    },
    totalPriceOfWallType(state, getters) {
      if(state.quote.bathroom.finitions.wall.zones === "allWall") {
        return getters.priceOfWallType * getters.wallAreaCalc;
      } else if(state.quote.bathroom.finitions.wall.zones === "wetWall") {
        return getters.priceOfWallType * getters.wetWallAreaCalc;
      } else {
        return 0;
      }
    },
    totalPriceOfWall(state, getters) {
      return getters.totalPriceOfWallType + getters.flatRateOfWallScreed;
    },
    priceOfCeiling(state) {
      if(state.quote.bathroom.finitions.ceiling.toDo) {
        return state.prices.bathroom.finition.ceiling.plaques;
      } else {
        return 0;
      }
    },
    totalOfSpots(state, getters) {
      let totalOfSpots = 0;
      if(state.quote.bathroom.finitions.ceiling.toDo) {
        if(getters.areaCalc <= 6) {
          totalOfSpots = 2;
        } else {
          totalOfSpots = 4;
        }
        return totalOfSpots;
      } else {
        return 0;
      }
    },
    priceOfSpots(state, getters) {
      return getters.totalOfSpots * state.prices.bathroom.finition.ceiling.spots;
    },
    totalPriceOfCeiling(state, getters) {
      return (getters.priceOfCeiling * getters.areaCalc) + getters.priceOfSpots;
    },
    totalPriceOfWc(state, getters) {
      return state.quote.bathroom.conception.wc.quantity * getters.priceOfWc;
    },
    getElectricityMargin(state) {
      return parseFloat(state.prices.bathroom.global.margin);
    },
    totalOfPriseInBedroom(state) {
      let bedroom = 0;
      state.quote.electricity.rooms.bedroom.listOfElecEquipment.forEach((element) => {
        bedroom += parseInt(element.prise);
      });
      return parseInt(bedroom);
    },
    totalOfPriseInBathroom(state) {
      let bathroom = 0;
      state.quote.electricity.rooms.bathroom.listOfElecEquipment.forEach((element) => {
        bathroom += parseInt(element.prise);
      });
      return parseInt(bathroom);
    },
    totalOfPriseInWc(state) {
      let wc = 0;
      state.quote.electricity.rooms.wc.listOfElecEquipment.forEach((element) => {
        wc += parseInt(element.prise);
      });
      return parseInt(wc);
    },
    totalOfPriseInKitchen(state) {
      let kitchen = 0;
      state.quote.electricity.rooms.kitchen.listOfElecEquipment.forEach((element) => {
        kitchen += parseInt(element.prise);
      });
      return parseInt(kitchen);
    },
    totalOfPriseInBureau(state) {
      let bureau = 0;
      state.quote.electricity.rooms.bureau.listOfElecEquipment.forEach((element) => {
        bureau += parseInt(element.prise);
      });
      return parseInt(bureau);
    },
    totalOfPriseInLiving(state) {
      let living = 0;
      state.quote.electricity.rooms.living.listOfElecEquipment.forEach((element) => {
        living += parseInt(element.prise);
      });
      return parseInt(living);
    },
    totalOfPriseInDiningRoom(state) {
      let diningRoom = 0;
      state.quote.electricity.rooms.diningRoom.listOfElecEquipment.forEach((element) => {
        diningRoom += parseInt(element.prise);
      });
      return parseInt(diningRoom);
    },
    totalOfPriseInLuandry(state) {
      let luandry = 0;
      state.quote.electricity.rooms.luandry.listOfElecEquipment.forEach((element) => {
        luandry += parseInt(element.prise);
      });
      return parseInt(luandry);
    },
    totalOfPriseInGrenier(state) {
      let grenier = 0;
      state.quote.electricity.rooms.grenier.listOfElecEquipment.forEach((element) => {
        grenier += parseInt(element.prise);
      });
      return parseInt(grenier);
    },
    totalOfPriseIn(state, getters) {
      let total = 0;
      total += getters.totalOfPriseInBedroom;
      total += getters.totalOfPriseInBathroom;
      total += getters.totalOfPriseInWc;
      total += getters.totalOfPriseInKitchen;
      total += getters.totalOfPriseInBureau;
      total += getters.totalOfPriseInLiving;
      total += getters.totalOfPriseInDiningRoom;
      total += getters.totalOfPriseInLuandry;
      total += getters.totalOfPriseInGrenier;
      return total;
    },
    totalOfPriseOutGarage(state) {
      let garage = 0;
      state.quote.electricity.rooms.garage.listOfElecEquipment.forEach((element) => {
        garage += parseInt(element.prise);
      });
      return parseInt(garage);
    },
    totalOfPriseOutCellar(state) {
      let cellar = 0;
      state.quote.electricity.rooms.cellar.listOfElecEquipment.forEach((element) => {
        cellar += parseInt(element.prise);
      });
      return parseInt(cellar);
    },
    totalOfPriseOutGardenShed(state) {
      let gardenShed = 0;
      state.quote.electricity.rooms.gardenShed.listOfElecEquipment.forEach((element) => {
        gardenShed += parseInt(element.prise);
      });
      return parseInt(gardenShed);
    },
    totalOfPriseOutExterior(state) {
      let exterior = 0;
      state.quote.electricity.exterior.listOfElecEquipment.forEach((element) => {
        exterior += parseInt(element.prise);
      });
      return parseInt(exterior);
    },
    totalOfPriseOut(state, getters) {
      let total = 0;
      total += getters.totalOfPriseOutGarage;
      total += getters.totalOfPriseOutCellar;
      total += getters.totalOfPriseOutGardenShed;
      total += getters.totalOfPriseOutExterior;
      return total;
    },
    totalPriceOfPriseIn(state, getters) {
      return getters.totalOfPriseIn * state.prices.electricity.equipment.priseIn * getters.getElectricityMargin;
    },
    totalPriceOfPriseOut(state, getters) {
      return getters.totalOfPriseOut * state.prices.electricity.equipment.priseOut * getters.getElectricityMargin;
    },
    totalOfInterrupteurInBedroom(state) {
      let bedroom = 0;
      state.quote.electricity.rooms.bedroom.listOfElecEquipment.forEach((element) => {
        bedroom += parseInt(element.interrupteur);
      });
      return parseInt(bedroom);
    },
    totalOfInterrupteurInBathroom(state) {
      let bathroom = 0;
      state.quote.electricity.rooms.bathroom.listOfElecEquipment.forEach((element) => {
        bathroom += parseInt(element.interrupteur);
      });
      return parseInt(bathroom);
    },
    totalOfInterrupteurInWc(state) {
      let wc = 0;
      state.quote.electricity.rooms.wc.listOfElecEquipment.forEach((element) => {
        wc += parseInt(element.interrupteur);
      });
      return parseInt(wc);
    },
    totalOfInterrupteurInKitchen(state) {
      let kitchen = 0;
      state.quote.electricity.rooms.kitchen.listOfElecEquipment.forEach((element) => {
        kitchen += parseInt(element.interrupteur);
      });
      return parseInt(kitchen);
    },
    totalOfInterrupteurInBureau(state) {
      let bureau = 0;
      state.quote.electricity.rooms.bureau.listOfElecEquipment.forEach((element) => {
        bureau += parseInt(element.interrupteur);
      });
      return parseInt(bureau);
    },
    totalOfInterrupteurInLiving(state) {
      let living = 0;
      state.quote.electricity.rooms.living.listOfElecEquipment.forEach((element) => {
        living += parseInt(element.interrupteur);
      });
      return parseInt(living);
    },
    totalOfInterrupteurInDiningRoom(state) {
      let diningRoom = 0;
      state.quote.electricity.rooms.diningRoom.listOfElecEquipment.forEach((element) => {
        diningRoom += parseInt(element.interrupteur);
      });
      return parseInt(diningRoom);
    },
    totalOfInterrupteurInLuandry(state) {
      let luandry = 0;
      state.quote.electricity.rooms.luandry.listOfElecEquipment.forEach((element) => {
        luandry += parseInt(element.interrupteur);
      });
      return parseInt(luandry);
    },
    totalOfInterrupteurInGrenier(state) {
      let grenier = 0;
      state.quote.electricity.rooms.grenier.listOfElecEquipment.forEach((element) => {
        grenier += parseInt(element.interrupteur);
      });
      return parseInt(grenier);
    },
    totalOfInterrupteurIn(state, getters) {
      let total = 0;
      total += getters.totalOfInterrupteurInBedroom;
      total += getters.totalOfInterrupteurInBathroom;
      total += getters.totalOfInterrupteurInWc;
      total += getters.totalOfInterrupteurInKitchen;
      total += getters.totalOfInterrupteurInBureau;
      total += getters.totalOfInterrupteurInLiving;
      total += getters.totalOfInterrupteurInDiningRoom;
      total += getters.totalOfInterrupteurInLuandry;
      total += getters.totalOfInterrupteurInGrenier;

      return total;
    },
    totalOfInterrupteurOutGarage(state) {
      let garage = 0;
      state.quote.electricity.rooms.garage.listOfElecEquipment.forEach((element) => {
        garage += parseInt(element.interrupteur);
      });
      return parseInt(garage);
    },
    totalOfInterrupteurOutCellar(state) {
      let cellar = 0;
      state.quote.electricity.rooms.cellar.listOfElecEquipment.forEach((element) => {
        cellar += parseInt(element.interrupteur);
      });
      return parseInt(cellar);
    },
    totalOfInterrupteurOutGardenShed(state) {
      let gardenShed = 0;
      state.quote.electricity.rooms.gardenShed.listOfElecEquipment.forEach((element) => {
        gardenShed += parseInt(element.interrupteur);
      });
      return parseInt(gardenShed);
    },
    totalOfInterrupteurOutExterior(state) {
      let exterior = 0;
      state.quote.electricity.exterior.listOfElecEquipment.forEach((element) => {
        exterior += parseInt(element.interrupteur);
      });
      return parseInt(exterior);
    },
    totalOfInterrupteurOut(state, getters) {
      let total = 0;
      total += getters.totalOfInterrupteurOutGarage;
      total += getters.totalOfInterrupteurOutCellar;
      total += getters.totalOfInterrupteurOutGardenShed;
      total += getters.totalOfInterrupteurOutExterior;

      return total;
    },
    totalPriceOfInterrupteurIn(state, getters) {
      return getters.totalOfInterrupteurIn * state.prices.electricity.equipment.interrupteurIn * getters.getElectricityMargin;
    },
    totalPriceOfInterrupteurOut(state, getters) {
      return getters.totalOfInterrupteurOut * state.prices.electricity.equipment.interrupteurOut * getters.getElectricityMargin;
    },
    totalOfPointLumineuxInBedroom(state) {
      let bedroom = 0;
      state.quote.electricity.rooms.bedroom.listOfElecEquipment.forEach((element) => {
        bedroom += parseInt(element.pointLumineux);
      });
      return parseInt(bedroom);
    },
    totalOfPointLumineuxInBathroom(state) {
      let bathroom = 0;
      state.quote.electricity.rooms.bathroom.listOfElecEquipment.forEach((element) => {
        bathroom += parseInt(element.pointLumineux);
      });
      return parseInt(bathroom);
    },
    totalOfPointLumineuxInWc(state) {
      let wc = 0;
      state.quote.electricity.rooms.wc.listOfElecEquipment.forEach((element) => {
        wc += parseInt(element.pointLumineux);
      });
      return parseInt(wc);
    },
    totalOfPointLumineuxInKitchen(state) {
      let kitchen = 0;
      state.quote.electricity.rooms.kitchen.listOfElecEquipment.forEach((element) => {
        kitchen += parseInt(element.pointLumineux);
      });
      return parseInt(kitchen);
    },
    totalOfPointLumineuxInBureau(state) {
      let bureau = 0;
      state.quote.electricity.rooms.bureau.listOfElecEquipment.forEach((element) => {
        bureau += parseInt(element.pointLumineux);
      });
      return parseInt(bureau);
    },
    totalOfPointLumineuxInLiving(state) {
      let living = 0;
      state.quote.electricity.rooms.living.listOfElecEquipment.forEach((element) => {
        living += parseInt(element.pointLumineux);
      });
      return parseInt(living);
    },
    totalOfPointLumineuxInDiningRoom(state) {
      let diningRoom = 0;
      state.quote.electricity.rooms.diningRoom.listOfElecEquipment.forEach((element) => {
        diningRoom += parseInt(element.pointLumineux);
      });
      return parseInt(diningRoom);
    },
    totalOfPointLumineuxInLuandry(state) {
      let luandry = 0;
      state.quote.electricity.rooms.luandry.listOfElecEquipment.forEach((element) => {
        luandry += parseInt(element.pointLumineux);
      });
      return parseInt(luandry);
    },
    totalOfPointLumineuxInGrenier(state) {
      let grenier = 0;
      state.quote.electricity.rooms.grenier.listOfElecEquipment.forEach((element) => {
        grenier += parseInt(element.pointLumineux);
      });
      return parseInt(grenier);
    },
    totalOfPointLumineuxIn(state, getters) {
      let total = 0;
      total += getters.totalOfPointLumineuxInBedroom;
      total += getters.totalOfPointLumineuxInBathroom;
      total += getters.totalOfPointLumineuxInWc;
      total += getters.totalOfPointLumineuxInKitchen;
      total += getters.totalOfPointLumineuxInBureau;
      total += getters.totalOfPointLumineuxInLiving;
      total += getters.totalOfPointLumineuxInDiningRoom;
      total += getters.totalOfPointLumineuxInLuandry;
      total += getters.totalOfPointLumineuxInGrenier;
      return total;
    },
    totalOfPointLumineuxOutGarage(state) {
      let garage = 0;
      state.quote.electricity.rooms.garage.listOfElecEquipment.forEach((element) => {
        garage += parseInt(element.pointLumineux);
      });
      return parseInt(garage);
    },
    totalOfPointLumineuxOutCellar(state) {
      let cellar = 0;
      state.quote.electricity.rooms.cellar.listOfElecEquipment.forEach((element) => {
        cellar += parseInt(element.pointLumineux);
      });
      return parseInt(cellar);
    },
    totalOfPointLumineuxOutGardenShed(state) {
      let gardenShed = 0;
      state.quote.electricity.rooms.gardenShed.listOfElecEquipment.forEach((element) => {
        gardenShed += parseInt(element.pointLumineux);
      });
      return parseInt(gardenShed);
    },
    totalOfPointLumineuxOutExterior(state) {
      let exterior = 0;
      state.quote.electricity.exterior.listOfElecEquipment.forEach((element) => {
        exterior += parseInt(element.pointLumineux);
      });
      return parseInt(exterior);
    },
    totalOfPointLumineuxOut(state, getters) {
      let total = 0;
      total += getters.totalOfPointLumineuxOutGarage;
      total += getters.totalOfPointLumineuxOutCellar;
      total += getters.totalOfPointLumineuxOutGardenShed;
      total += getters.totalOfPointLumineuxOutExterior;
      return total;
    },
    totalPriceOfPointLumineuxIn(state, getters) {
      return getters.totalOfPointLumineuxIn * state.prices.electricity.equipment.pointLumineux * getters.getElectricityMargin;
    },
    totalPriceOfPointLumineuxOut(state, getters) {
      return getters.totalOfPointLumineuxOut * state.prices.electricity.equipment.pointLumineux * getters.getElectricityMargin;
    },
    typeOfRenovation(state) {
      if(state.quote.electricity.renovation.level === "low") {
        return "Rénovation partielle";
      } else {
        return "Rénovation complète";
      }
    },
    priceOfTableau(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.tableau * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfDisjoncteurTete(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.disjoncteurTete * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfDisjoncteurDiff63(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.disjoncteurDiff63 * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfDisjoncteurDiff40(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.disjoncteurDiff40 * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfDisjoncteur16(state, getters) {
      return state.prices.electricity.technic.disjoncteur16 * getters.getElectricityMargin;
    },
    priceOfDisjoncteur25(state, getters) {
      return state.prices.electricity.technic.disjoncteur25 * getters.getElectricityMargin;
    },
    priceOfCoffretGestionnaire(state, getters) {
      if(state.quote.electricity.technic.coffretGestionnaire) {
        return state.prices.electricity.technic.coffretGestionnaire * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfCableCompteurTableau(state, getters) {
      if(state.quote.electricity.technic.cableCompteurTableau) {
        return state.prices.electricity.technic.cableCompteurTableau * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfPriseTerre(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.priseTerre * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfForfaitMiseTerre(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return state.prices.electricity.technic.forfaitMiseTerre * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    priceOfReception(state) {
      if(state.quote.electricity.technic.reception) {
        return state.prices.electricity.technic.reception;
      } else {
        return 0;
      }
    },
    priceOfAlarm(state) {
      if(state.quote.electricity.alarm.toDo) {
        return state.prices.electricity.alarm;
      } else {
        return 0;
      }
    },
    priceOfRechargeVoiture(state, getters) {
      if(state.quote.electricity.chargingPoint.toDo) {
        return state.prices.electricity.borneVoiture * getters.getElectricityMargin;
      } else {
        return 0;
      }
    },
    totalPriceOfTableau(state, getters) {
      if(state.quote.electricity.technic.tableau) {
        return getters.priceOfTableau + getters.priceOfDisjoncteurTete + getters.priceOfDisjoncteurDiff63 + getters.priceOfDisjoncteurDiff40 + getters.priceOfPriseTerre + getters.priceOfForfaitMiseTerre;
      } else {
        return 0;
      }
    },
    totalOfDisjoncteurs16(state) {
      let total = 0;
      total += state.quote.electricity.rooms.bedroom.quantity;
      total += state.quote.electricity.rooms.bathroom.quantity;
      total += state.quote.electricity.rooms.wc.quantity;
      if(state.quote.electricity.rooms.kitchen.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.living.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.diningRoom.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.luandry.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.grenier.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.bureau.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.garage.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.cellar.toDo) {
        total += 1;
      }
      if(state.quote.electricity.rooms.gardenShed.toDo) {
        total += 1;
      }
      return total;
    },
    totalPriceOfDisjoncteurs16(state, getters) {
      return getters.totalOfDisjoncteurs16 * getters.priceOfDisjoncteur16;
    },
    totalOfDisjoncteurs25(state) {
      let total = 0;
      total += state.quote.electricity.rooms.kitchen.kitchenEquipment.length;
      return total;
    },
    totalPriceOfDisjoncteurs25(state, getters) {
      return getters.totalOfDisjoncteurs25 * getters.priceOfDisjoncteur25;
    },
    pourcentageOfTva(state) {
      if(state.quote.basisInformations.dateOfConstruction === 0 || state.quote.basisInformations.dateOfConstruction === 1) {
        return 21;
      } else {
        return 6;
      }
    },
    totalOfWaterPoints(state) {
      if((state.quote.bathroom.conception.sink.vasque.type === "lavabo" && state.quote.bathroom.conception.sink.vasque.quantity === 2)) {
        const totalOfSink = 1;
        return parseInt(state.quote.bathroom.conception.baignoire.quantity) + parseInt(state.quote.bathroom.conception.douche.quantity) + parseInt(totalOfSink) + parseInt(state.quote.bathroom.conception.wc.quantity);
      } else {
        return parseInt(state.quote.bathroom.conception.baignoire.quantity) + parseInt(state.quote.bathroom.conception.douche.quantity) + parseInt(state.quote.bathroom.conception.sink.vasque.quantity) + parseInt(state.quote.bathroom.conception.wc.quantity);
      }      
    },
    totalPriceOfWaterPoints(state, getters) {
      if(getters.totalOfWaterPoints > 0) {
        return (state.prices.bathroom.plumbing.flatRate + (getters.totalOfWaterPoints * state.prices.bathroom.plumbing.pointOfWater)) * getters.getBathroomMargin;
      } else {
        return 0;
      }
    },
    totalPriceOfDemolition(state, getters) {
      return getters.typeOfDemolition.price * getters.getBathroomMargin;
    },
    totalPriceHtva(state, getters) {
      return getters.totalPriceOfWaterPoints
      + getters.totalPriceOfWc
      + getters.totalPriceOfDemolition
      + getters.totalPriceOfBaignoireSet
      + getters.totalPriceOfDoucheSet
      + getters.totalPriceOfSinkSet
      + getters.totalPriceOfUnitInBedroom
      + getters.totalPriceOfUnitInBureau
      + getters.totalPriceOfUnitInLiving
      + getters.totalPriceOfUnitInDiningRoom
      + getters.totalPriceOfUnitOut
      + getters.totalPriceOfLignesFrigorifiques
      + getters.totalPriceOfCondensat
      + getters.totalPriceOfCablageUnitIn
      + getters.totalPriceOfGround
      + getters.totalPriceOfWall
      + getters.totalPriceOfCeiling
      + getters.totalPriceOfPointLumineuxIn
      + getters.totalPriceOfPointLumineuxOut
      + getters.totalPriceOfPriseIn
      + getters.totalPriceOfPriseOut
      + getters.totalPriceOfInterrupteurIn
      + getters.totalPriceOfInterrupteurOut
      + getters.totalPriceOfAlimElecExt
      + getters.priceOfAlarm
      + getters.totalPriceOfTableau
      + getters.totalPriceOfDisjoncteurs16
      + getters.totalPriceOfDisjoncteurs25
      + getters.priceOfReception;
    },
    totalPriceTvac(state, getters) {
      return getters.totalPriceHtva * (1 + (getters.pourcentageOfTva / 100));
    }
  }
});

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}

export default store;
<template>
  <fieldset>
    <h2>Faut-il une finition murale de votre salle de bain ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="wallBathroom" :value="true" v-model="quote.bathroom.finitions.wall.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="wallBathroom" :value="false" v-model="quote.bathroom.finitions.wall.toDo" @click.once="validateStep10">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.bathroom.finitions.wall.toDo" class="moreQuestions">
      <h3>Pour quelles zones ?</h3>
      <ul >
        <li>
          <input type="radio" id="allWall" name="zones" value="allWall" v-model="quote.bathroom.finitions.wall.zones" @click="scrollToNextQuestion(1)">
          <label for="allWall">Tous les murs</label>
        </li>
        <li>
          <input type="radio" id="wetWall" name="zones" value="wetWall" v-model="quote.bathroom.finitions.wall.zones" @click="scrollToNextQuestion(1)">
          <label for="wetWall">Zones humides uniquement</label>
        </li>
      </ul>
      <p class="moreInformations">Les zones humides comprennent le lavabo, le WC et la baignoire (hors douche)</p>
    </div>
    <div v-show="quote.bathroom.finitions.wall.zones != null && quote.bathroom.finitions.wall.toDo" class="moreQuestions">
      <h3>Quel type de finition murale souhaitez-vous ?</h3>
      <ul >
        <li>
          <input type="radio" id="faience" name="wall" value="faience" v-model="quote.bathroom.finitions.wall.type" @click.once="validateStep10">
          <label for="faience">Faïence</label>
        </li>
        <li>
          <input type="radio" id="panneau" name="wall" value="panneau" v-model="quote.bathroom.finitions.wall.type" @click.once="validateStep10">
          <label for="panneau">Panneaux muraux</label>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep10() {
      this.quote.validStep = 10; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
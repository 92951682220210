<template>
  <fieldset>
    <h2>Combien de chambres faut-il climatiser&nbsp;?</h2>
    <div class="form-group">
      <div class="input-group">
        <label for="quantity">Nombre de chambres</label>
        <select name="quantity" id="quantity" autofocus v-model="quote.airConditioning.rooms.bedroom.quantity">
          <option :value="0">0</option>
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
          <option :value="4">4</option>
          <option :value="5">5</option>
        </select>
      </div>
      <button class="btn btn-action btn-secondary" @click.prevent="validateStep2">
        Valider
      </button>
    </div>
    
  </fieldset>
</template>

<script>



export default {

  props: ['currentStep'],

  computed: {
    quote() {
      return this.$store.state.quote;
    }
  },
  methods: {
    validateStep2() {
      if(this.quote.validStep < this.currentStep) {
        this.quote.validStep = 2; 
      }
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
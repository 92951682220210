<template>
  <fieldset>
    <h2>Faut-il climatiser un bureau&nbsp;?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="bureau" :value="true" v-model="quote.airConditioning.rooms.bureau.toDo" @click="validateStep3">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="bureau" :value="false" v-model="quote.airConditioning.rooms.bureau.toDo" @click="validateStep3">
        <label for="no">Non</label>
      </li>
    </ul>

  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
  },
  methods: {
    validateStep3() {
      this.quote.validStep = 3; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <section class="form formQuote quoteElectricity">
    <div class="container">
      <div class="navigationForm">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li><router-link :to="'/devis-en-ligne'">Devis en ligne</router-link></li>
          <li>Électricité</li>
        </ul>
      </div>
      <div class="finalQuote">
          <h2>Récapitulatif de votre devis</h2>
          <div class="totalPrice">
            <h3>Prix estimatif du chantier</h3>
            <p class="htvaPrice">{{ totalPriceHtva | numeral('0.00') }} <span>€ HTVA</span></p>
            <p class="tvacPrice">{{ totalPriceTvac | numeral('0.00') }} <span>€ TVAC {{ pourcentageOfTva }}%</span></p>
            <p>Ce prix est basé sur vos réponses et dépend du niveau de finition choisi pour chaque élément. Dans tous les cas, il sera réévalué après une visite de notre technicien pour un devis plus précis. Il sera alors possible d'ajouter diverses options.</p>
          </div>
          <div class="quoteDescription">
            <h3>Informations personnelles</h3>
            <table>
              <tr class="twoCols">
                <td>Nom et prénom</td>
                <td>{{ quoteUserInformations.name }}</td>
              </tr>
              <tr class="twoCols">
                <td>Adresse email</td>
                <td>{{ quoteUserInformations.emailAddress}}</td>
              </tr>
              <tr class="twoCols">
                <td>Code postal</td>
                <td>{{ quoteUserInformations.postCode }}</td>
              </tr>
              
            </table>
            <h3>Données de base</h3>
            <table>
              <tr class="twoCols">
                <td>Période de construction du bâtiment</td>
                <td>{{ typeOfConstruction }}</td>
              </tr>
            </table>
            <h3>Pièces à équiper</h3>
            <ul class="piecesAEquiper">
               <li v-for="(element, index) in quote.electricity.rooms.bedroom.listOfElecEquipment" :key="'bedroom'+index">
                <h3>Chambre {{ index + 1}}</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.bathroom.listOfElecEquipment" :key="'bathroom'+index">
                <h3>Salle de bain {{ index + 1}}</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input class="mini" type="number" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input class="mini" type="number" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.wc.listOfElecEquipment" :key="'wc'+index">
                <h3>WC {{ index + 1}}</h3>
                <ul>
                  <li>Prises : <input class="mini" type="number" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input class="mini" type="number" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input class="mini" type="number" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.kitchen.listOfElecEquipment" :key="'kitchen'+index">
                <h3>Cuisine</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.bureau.listOfElecEquipment" :key="'bureau'+index">
                <h3>Bureau</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.living.listOfElecEquipment" :key="'living'+index">
                <h3>Salon</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.diningRoom.listOfElecEquipment" :key="'diningRoom'+index">
                <h3>Salle à manger</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.luandry.listOfElecEquipment" :key="'luandry'+index">
                <h3>Buanderie</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.grenier.listOfElecEquipment" :key="'grenier'+index">
                <h3>Grenier</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.garage.listOfElecEquipment" :key="'garage'+index">
                <h3>Garage</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.cellar.listOfElecEquipment" :key="'cellar'+index">
                <h3>Cave</h3>
                <ul>
                  <li>Prises : <input type="number" class="mini" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input type="number" class="mini" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input type="number" class="mini" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.rooms.gardenShed.listOfElecEquipment" :key="'gardenShed'+index">
                <h3>Abri de jardin</h3>
                <ul>
                  <li>Prises : <input class="mini" type="number" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input class="mini" type="number" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input class="mini" type="number" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
              <li v-for="(element, index) in quote.electricity.exterior.listOfElecEquipment" :key="'exterior'+index">
                <h3>Extérieur</h3>
                <ul>
                  <li>Prises : <input class="mini" type="number" v-model="element.prise" min="0"></li>
                  <li>Interrupteurs : <input class="mini" type="number" v-model="element.interrupteur" min="0"></li>
                  <li>Points lumineux : <input class="mini" type="number" v-model="element.pointLumineux" min="0"></li>
                </ul>
              </li>
            </ul>
            <h3>Récapitulatif des équipements</h3>
            <table>
              <tr class="fourCols">
                <th>Intitulé</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix total</th>
              </tr>
              <tr class="fourCols">
                <td>Nombre total de prises - pose encastrée</td>
                <td>{{ totalOfPriseIn }}</td>
                <td>{{ totalPriceOfPriseIn / totalOfPriseIn | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfPriseIn | numeral('0.00') }} €</td>
              </tr>
              <tr class="fourCols">
                <td>Nombre total de prises - pose apparente</td>
                <td>{{ totalOfPriseOut }}</td>
                <td>{{ totalPriceOfPriseOut / totalOfPriseOut | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfPriseOut | numeral('0.00') }} €</td>
              </tr>
              <tr class="fourCols">
                <td>Nombre total d'interrupteurs - pose encastrée</td>
                <td>{{ totalOfInterrupteurIn }}</td>
                <td>{{ totalPriceOfInterrupteurIn / totalOfInterrupteurIn | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfInterrupteurIn | numeral('0.00') }} €</td>
              </tr>
              <tr class="fourCols">
                <td>Nombre total d'interrupteurs - pose apparente</td>
                <td>{{ totalOfInterrupteurOut }}</td>
                <td>{{ totalPriceOfInterrupteurOut / totalOfInterrupteurOut | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfInterrupteurOut | numeral('0.00') }} €</td>
              </tr>
              <tr class="fourCols">
                <td>Nombre total de points lumineux</td>
                <td>{{ totalOfPointLumineuxIn + totalOfPointLumineuxOut }}</td>
                <td>{{ (totalPriceOfPointLumineuxIn + totalPriceOfPointLumineuxOut) / (totalOfPointLumineuxIn + totalOfPointLumineuxOut) | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfPointLumineuxIn + totalPriceOfPointLumineuxOut | numeral('0.00') }} €</td>
              </tr>
            </table>
            <h3>Tableau électrique</h3>
            <table>
              <tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
              <tr class="twoCols" v-if="quote.electricity.technic.tableau">
                <td>
                  Coffret tableau
                  <p>Comprenant le coffret, le disjoncteur de tête, les différentiels 300mA et 30mA et la prise de terre.</p>
                </td>
                <td>{{ totalPriceOfTableau | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>
                  Disjoncteurs
                  <p>Pour l'ensemble des pièces et des équipements de la cuisine.</p>
                </td>
                <td>{{ totalPriceOfDisjoncteurs16 + totalPriceOfDisjoncteurs25 | numeral('0.00') }} €</td>
              </tr>

            </table>
            <h3 v-if="quote.electricity.alarm.toDo || quote.electricity.supplements.toDo">Options</h3>
            <table v-if="quote.electricity.alarm.toDo || quote.electricity.supplements.toDo">
              <tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
              <tr class="twoCols" v-if="quote.electricity.alarm.toDo">
                <td>
                  Alarme
                  <p>De base, l'alarme comprend 5 détecteurs, 2 claviers, 1 sirène intérieure et 1 sirène extérieure. La certification Insert est incluse.</p>
                </td>
                <td>{{ priceOfAlarm | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols" v-if="quote.electricity.supplements.toDo">
                <td>
                  Prises supplémentaires
                  <p>Par exemple : borne voiture, climatisation, chauffage électrique, éclairage extérieur, PAC piscine ...</p>
                  <p>Le budget de ce poste sera défini lors de la visite sur place.</p>
                </td>
                <td>A déterminer</td>
              </tr>
              <tr class="twoCols" v-if="quote.electricity.technic.reception">
                <td>
                  Réception par un organisme agréé
                  <p>Avec plans As-Built</p>
                </td>
                <td>{{ priceOfReception | numeral('0.00') }} €</td>
              </tr>
            </table>
          </div>
          <pdf-quote-electricity></pdf-quote-electricity>
        </div>
      <div class="quoteActions">
        <div class="btn-group">
          <button class="btn btn-dark btn-action" @click="savePdf">Enregistrer le devis</button>
          <button class="btn btn-secondary btn-action" @click="sendToAll">Envoyer le PDF par mail</button>
        </div>
        
      </div> 
      <call-me-quote></call-me-quote>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import emailjs from 'emailjs-com';
import pdfQuoteElectricity from '../../../components/Quote/QuoteElectricity/PdfQuoteElectricity.vue';
import callMeQuote from '../CallMeQuote.vue';

export default {
  components: {
    pdfQuoteElectricity,
    callMeQuote
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const priceDiv = document.querySelector('.totalPrice');
      const quoteDescriptionDiv = document.querySelector('.quoteDescription');
      const offsetPriceDiv = priceDiv.offsetTop;
      const newMarginTop = priceDiv.offsetHeight + 120 + 'px';

      if(window.scrollY > offsetPriceDiv) {
        priceDiv.classList.add('fixed');
        quoteDescriptionDiv.style.marginTop = newMarginTop;
        
      } else {
        priceDiv.classList.remove('fixed');
        quoteDescriptionDiv.style.marginTop = 0;
      }
    },
    generatePdf () {
      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);

      doc.autoTable({
        head: [['Récapitulatif']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120,
      });
      autoTable(doc, { html: '#quoteToPdfRecapitulatif', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'right', minCellWidth: 30 }, 3: { halign: 'right', minCellWidth: 30}}});
    
      if(this.quote.electricity.alarm.toDo || this.quote.electricity.supplements.toDo || this.quote.electricity.technic.reception) {
        doc.autoTable({
          head: [['Options']],
          headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        });
        autoTable(doc, { html: '#quoteToPdfOptionsElectricity', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      }
      
      doc.autoTable({
        head: [['Tableau électrique']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
      });
      autoTable(doc, { html: '#quoteToPdfTableauElectricity', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30}}});
    
      doc.autoTable({
        head: [['Description des éléments par pièce']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
      });
      autoTable(doc, { html: '#quoteToPdfPiecesDescription', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right' }, 4: { halign: 'right', minCellWidth: 30 } }});

      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});

      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });

      return doc;
      
    },
    toBase64() {
      let doc = this.generatePdf();
      let pdfBase64 = doc.output('datauristring');
      return pdfBase64;
    },
    pdfName() {
      return this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
    },
    sendToAll() {
      this.quote.version++;
      this.sendPdf('admin');
      this.sendPdf('user');
    },
    sendPdf(to_user) {
      let waiting = document.querySelector('#waiting');
      waiting.classList.add('active');
      let pdfBase64 = this.toBase64();
      let templateId = '';
      let name = this.pdfName();
      if(to_user === 'admin') {
        templateId = 'template_0dsylcs';
      } else {
        templateId = 'template_4mq3hom';
      }
      emailjs.send('service_0g7pobn', templateId, {
                  to_name: "Jérôme",
                  user_name: this.quoteUserInformations.name,
                  user_postCode: this.quoteUserInformations.postCode,
                  user_email: this.quoteUserInformations.emailAddress,
                  pdf_file: pdfBase64,
                  pdf_name: name
              }, 'user_HbY4OMU3yW41VYggzuODG').then((result) => {
                      console.log('SUCCESS!', result.status, result.text);
                      waiting.classList.remove('active');

                  }, (error) => {
                      console.log('FAILED...', error);
                      waiting.classList.remove('active');
                  });
    
    },
    savePdf() {
      this.quote.version++;
      console.log('save');
      let doc = this.generatePdf();
      let name = this.pdfName();
      doc.save(name);
      this.sendPdf('admin');
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'totalOfPriseIn',
      'totalPriceOfPriseIn',
      'totalOfPriseOut',
      'totalPriceOfPriseOut',
      'totalOfInterrupteurIn',
      'totalPriceOfInterrupteurIn',
      'totalOfInterrupteurOut',
      'totalPriceOfInterrupteurOut',
      'totalOfPointLumineuxIn',
      'totalPriceOfPointLumineuxIn',
      'totalOfPointLumineuxOut',
      'totalPriceOfPointLumineuxOut',
      'typeOfRenovation',
      'priceOfTableau',
      'priceOfDisjoncteurTete',
      'priceOfDisjoncteurDiff63',
      'priceOfDisjoncteurDiff40',
      'priceOfDisjoncteur16',
      'totalOfDisjoncteurs16',
      'totalPriceOfDisjoncteurs16',
      'priceOfDisjoncteur25',
      'totalOfDisjoncteurs25',
      'totalPriceOfDisjoncteurs25',
      'priceOfCoffretGestionnaire',
      'priceOfCableCompteurTableau',
      'priceOfPriseTerre',
      'priceOfForfaitMiseTerre',
      'priceOfReception',
      'priceOfRechargeVoiture',
      'priceOfAlarm',

      'totalPriceOfTableau',
      'totalPriceHtva',
      'totalPriceTvac',
      'typeOfDemolition',
      'typeOfConstruction',
      'pourcentageOfTva'
    ])
  }
}
</script>

<style>

</style>
<template>
  <section class="quoteSteps">
    <div class="container">
      <header>
        <h1><strong>Votre devis en ligne&nbsp;:&nbsp;</strong>simple, rapide et efficace !</h1>
      </header>
      <ul>
        <home-quote-steps-element v-for="step in quoteSteps" :key="step.id" :step="step"></home-quote-steps-element>
      </ul>
      <div class="btn-group">
          <router-link :to="'/devis-en-ligne'" class="btn btn-action btn-primary">Je souhaite obtenir un devis</router-link>
        </div>
    </div>
  </section>
</template>

<script>

import HomeQuoteStepsElement from './HomeQuoteStepsElement.vue';

export default {
  components: {
    HomeQuoteStepsElement
  },
  props: ['quoteSteps']
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Quel type de démolition faut-il prévoir ?</h2>
    <ul>
      <li>
        <input type="radio" id="smallDemolition" name="demolition" value="small" v-model="quote.bathroom.preparation.demolition.type" @click="validateStep3">
        <label for="smallDemolition">Petite</label>
      </li>
      <li>
        <input type="radio" id="middleDemolition" name="demolition" value="middle" v-model="quote.bathroom.preparation.demolition.type" @click="validateStep3">
        <label for="middleDemolition">Moyenne</label>
      </li>
      <li>
        <input type="radio" id="bigDemolition" name="demolition" value="big" v-model="quote.bathroom.preparation.demolition.type" @click="validateStep3">
        <label for="bigDemolition">Grosse</label>
      </li>
    </ul>
    <p class="moreInformations">Petite : pas de démolition ou très peu.</p>
    <p class="moreInformations">Moyenne : détapisser, enlever un revêtement léger ...</p>
    <p class="moreInformations">Grosse : retirer du carrelage, démonter les sanitaires, décloisonner, retirer l'électricité ...</p>

  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    validateStep3() {
      this.quote.validStep = 3; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <section class="form formQuote quoteAirConditioning">
    <div class="container">
      <div class="navigationForm">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li><router-link :to="'/devis-en-ligne'">Devis en ligne</router-link></li>
          <li>Climatisation</li>
        </ul>
      </div>
      <div class="finalQuote">
          <h2>Récapitulatif de votre devis</h2>
          <div class="totalPrice">
            <h3>Prix estimatif du chantier</h3>
            <p class="htvaPrice">{{ totalPriceHtva | numeral('0.00') }} <span>€ HTVA</span></p>
            <p class="tvacPrice">{{ totalPriceTvac | numeral('0.00') }} <span>€ TVAC {{ pourcentageOfTva }}%</span></p>
            <p>Ce prix est basé sur vos réponses et dépend du niveau de finition choisi pour chaque élément. Dans tous les cas, il sera réévalué après une visite de notre technicien pour un devis plus précis. Il sera alors possible d'ajouter diverses options.</p>
            <p>Le devis ci-dessous s'entend fourniture, pose et raccordement compris.</p>
          </div>
          <div class="quoteDescription">
            <h3>Informations personnelles</h3>
            <table>
              <tr class="twoCols">
                <td>Nom et prénom</td>
                <td>{{ quoteUserInformations.name }}</td>
              </tr>
              <tr class="twoCols">
                <td>Adresse email</td>
                <td>{{ quoteUserInformations.emailAddress}}</td>
              </tr>
              <tr class="twoCols">
                <td>Code postal</td>
                <td>{{ quoteUserInformations.postCode }}</td>
              </tr>
              
            </table>
            <h3>Données de base</h3>
            <table>
              <tr class="twoCols">
                <td>Période de construction du bâtiment</td>
                <td>{{ typeOfConstruction }}</td>
              </tr>
            </table>
            <h3>Fourniture et pose des unités intérieures pour ...</h3>
            <table>
              <tr class="fiveCols">
                <th>Intitulé</th>
                <th>Gamme</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix total</th>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.bedroom.toDo">
                <td>Chambres</td>
                <td class="tdGamme">
                  <input type="radio" id="gammeChambreBasis" name="gammeChambre" :value="1" v-model="quote.airConditioning.rooms.bedroom.gamme">
                  <label for="gammeChambreBasis">Standard</label>
                  <input type="radio" id="gammeChambreTop" name="gammeChambre" :value="2" v-model="quote.airConditioning.rooms.bedroom.gamme">
                  <label for="gammeChambreTop">Design</label>
                </td>
                <td>
                  <select name="quantity" id="quantity" v-model="quote.airConditioning.rooms.bedroom.quantity">
                    <option :value="0">0</option>
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    <option :value="5">5</option>
                  </select>
                </td>
                <td>{{ priceOfUnitInBedroom | numeral('0.00') }} €</td>
                <td>{{ totalPriceOfUnitInBedroom | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.bureau.toDo">
                <td>Bureau</td>
                <td class="tdGamme">
                  <input type="radio" id="gammeBureauBasis" name="gammeBureau" :value="1" v-model="quote.airConditioning.rooms.bureau.gamme">
                  <label for="gammeBureauBasis">Standard</label>
                  <input type="radio" id="gammeBureauTop" name="gammeBureau" :value="2" v-model="quote.airConditioning.rooms.bureau.gamme">
                  <label for="gammeBureauTop">Design</label>
                </td>
                <td>
                  <input type="checkbox" id="quantiteBureau" name="quantiteBureau" v-model="quote.airConditioning.rooms.bureau.quantity">
                  <label for="quantiteBureau">A faire</label>
                </td>
                <td></td>
                <td>{{ totalPriceOfUnitInBureau | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.living.toDo">
                <td v-if="quote.airConditioning.rooms.living.size === 'small'">Salon de moins de 40m²</td>
                <td v-else>Salon égal ou supérieur à 40m²</td>
                <td class="tdGamme">
                  <input type="radio" id="gammeLivingBasis" name="gammeLiving" :value="1" v-model="quote.airConditioning.rooms.living.gamme">
                  <label for="gammeLivingBasis">Standard</label>
                  <input type="radio" id="gammeLivingTop" name="gammeLiving" :value="2" v-model="quote.airConditioning.rooms.living.gamme">
                  <label for="gammeLivingTop">Design</label>
                </td>
                <td>
                  <input type="checkbox" id="quantiteLiving" name="quantiteLiving" v-model="quote.airConditioning.rooms.living.quantity">
                  <label for="quantiteLiving">A faire</label>
                </td>
                <td></td>
                <td>{{ totalPriceOfUnitInLiving | numeral('0.00') }} €</td>
              </tr>
              <tr class="fiveCols" v-if="quote.airConditioning.rooms.diningRoom.toDo">
                <td v-if="quote.airConditioning.rooms.diningRoom.kitchenOpen">Salle à manger ouverte sur la cuisine</td>
                <td v-else>Salle à manger individuelle</td>
                <td class="tdGamme">
                  <input type="radio" id="gammeDiningRoomBasis" name="gammeDiningRoom" :value="1" v-model="quote.airConditioning.rooms.diningRoom.gamme">
                  <label for="gammeDiningRoomBasis">Standard</label>
                  <input type="radio" id="gammeDiningRoomTop" name="gammeDiningRoom" :value="2" v-model="quote.airConditioning.rooms.diningRoom.gamme">
                  <label for="gammeDiningRoomTop">Design</label>
                </td>
                <td>
                  <input type="checkbox" id="quantiteDiningRoom" name="quantiteDiningRoom" v-model="quote.airConditioning.rooms.diningRoom.quantity">
                  <label for="quantiteDiningRoom">A faire</label>
                </td>
                <td></td>
                <td>{{ totalPriceOfUnitInDiningRoom | numeral('0.00') }} €</td>
              </tr>
              <tr >
                <td colspan="5" style="text-align: center">
                  La gamme design intègre un filtre Covid !<br>
                 Télécharger les fiches techniques : <a href="https://www.dbdtech.be/fiches/dbdtech-climatisation-design.pdf" target="blank" class="link link-fiche">Gamme design</a> - <a href="https://www.dbdtech.be/fiches/dbdtech-climatisation-standard.pdf" target="blank" class="link link-fiche">Gamme standard</a>
                </td>
              </tr>
            </table>
            <h3>Fourniture et pose des unités extérieures</h3>
            <table>
              <tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
              <tr class="twoCols">
                <td>Unités extérieures
                  <p>Le nombre d'unités extérieures sera déterminé lors de la visite sur place.</p>
                </td>
                <td>{{ totalPriceOfUnitOut | numeral('0.00') }} €</td>
              </tr>
            </table>
            <h3>Travaux divers</h3>
            <table>
              <tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
              <tr class="twoCols">
                <td>Lignes frigorifiques
                  <p>Fourniture et pose entre unité(s) intérieure(s) et extérieure(s)</p>
                </td>
                <td>{{ totalPriceOfLignesFrigorifiques | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Câblage
                  <p>Fourniture, pose et raccordement entre unité(s) intérieure(s) et extérieure(s)</p>
                </td>
                <td>{{ totalPriceOfCablageUnitIn | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Condensat
                  <p>Fourniture et pose du réseau</p>
                </td>
                <td>{{ totalPriceOfCondensat | numeral('0.00') }} €</td>
              </tr>
            </table>
            <h3>Options</h3>
            <table>
              <tr class="twoCols">
                <th>Intitulé</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Prix total</th>
              </tr>
              <tr class="fiveCols">
                <td>Alimentation électrique pour le groupe extérieur
                  <p v-if="!quote.airConditioning.options.alimElecExt">Prévoir l'alimentation électrique au niveau des unités extérieures.</p>
                </td>
                <td></td>
                <td>
                  <input type="checkbox" id="alimElecExtToDo" name="alimElecExtToDo" v-model="quote.airConditioning.options.alimElecExt">
                  <label for="alimElecExtToDo">A faire</label>
                </td>
                <td></td>
                <td>{{ totalPriceOfAlimElecExt | numeral('0.00') }} €</td>
              </tr>
            </table>
          </div>
          <pdf-quote-air-conditioning></pdf-quote-air-conditioning>
        </div>
      <div class="quoteActions">
        <div class="btn-group">
          <button class="btn btn-dark btn-action" @click="savePdf">Enregistrer le devis</button>
          <button class="btn btn-secondary btn-action" @click="sendToAll">Envoyer le PDF par mail</button>
        </div>
        
      </div> 
      <call-me-quote></call-me-quote>

    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import emailjs from 'emailjs-com';
import pdfQuoteAirConditioning from '../../../components/Quote/QuoteAirConditioning/PdfQuoteAirConditioning.vue';
import callMeQuote from '../CallMeQuote.vue';

export default {
  components: {
    pdfQuoteAirConditioning,
    callMeQuote
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const priceDiv = document.querySelector('.totalPrice');
      const quoteDescriptionDiv = document.querySelector('.quoteDescription');
      const offsetPriceDiv = priceDiv.offsetTop;
      const newMarginTop = priceDiv.offsetHeight + 120 + 'px';

      if(window.scrollY > offsetPriceDiv) {
        priceDiv.classList.add('fixed');
        quoteDescriptionDiv.style.marginTop = newMarginTop;
        
      } else {
        priceDiv.classList.remove('fixed');
        quoteDescriptionDiv.style.marginTop = 0;
      }
    },
    generatePdf () {

      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);

      doc.autoTable({
        head: [['Fourniture et pose des unités intérieures pour ...']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120,
      });
      autoTable(doc, { html: '#quoteToPdfPieces', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right' }, 4: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Fourniture et pose des unités extérieures']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfExterieur', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Travaux divers']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfDivers', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 } }});
      doc.autoTable({
        head: [['Options']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfOptions', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      
      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});
      

      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });

      autoTable(doc, { html: '#quoteToPdfFiches', styles: {alternateRowStyles: {fillColor: false}}});

      return doc;
      
    },
    toBase64() {
      let doc = this.generatePdf();
      let pdfBase64 = doc.output('datauristring');
      return pdfBase64;
    },
    pdfName() {
      return this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
    },
    sendToAll() {
      this.quote.version++;
      this.sendPdf('admin');
      this.sendPdf('user');
    },
    sendPdf(to_user) {
      let waiting = document.querySelector('#waiting');
      waiting.classList.add('active');
      let pdfBase64 = this.toBase64();
      let templateId = '';
      let name = this.pdfName();
      if(to_user === 'admin') {
        templateId = 'template_0dsylcs';
      } else {
        templateId = 'template_4mq3hom';
      }
      emailjs.send('service_0g7pobn', templateId, {
                  to_name: "Jérôme",
                  user_name: this.quoteUserInformations.name,
                  user_postCode: this.quoteUserInformations.postCode,
                  user_email: this.quoteUserInformations.emailAddress,
                  pdf_file: pdfBase64,
                  pdf_name: name
              }, 'user_HbY4OMU3yW41VYggzuODG').then((result) => {
                      console.log('SUCCESS!', result.status, result.text);
                      waiting.classList.remove('active');

                  }, (error) => {
                      console.log('FAILED...', error);
                      waiting.classList.remove('active');
                  });
    
    },
    savePdf() {
      this.quote.version++;
      console.log('save');
      let doc = this.generatePdf();
      let name = this.pdfName();
      doc.save(name);
      this.sendPdf('admin');
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'totalPriceHtva',
      'totalPriceTvac',
      'typeOfConstruction',
      'pourcentageOfTva',
      'priceOfUnitInBedroom',
      'totalPriceOfUnitInBedroom',
      'totalPriceOfUnitInBureau',
      'totalPriceOfUnitInLiving',
      'totalPriceOfUnitInDiningRoom',
      'totalPriceOfUnitOut',
      'totalOfWatts',
      'totalPriceOfLignesFrigorifiques',
      'totalPriceOfCablageUnitIn',
      'totalPriceOfCondensat',
      'totalPriceOfAlimElecExt'
    ]), 
  }
}
</script>

<style>

</style>
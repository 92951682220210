import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
 Vue.use(vueNumeralFilterInstaller, { locale: 'fr' });
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-W2CSY7H76P" }
});

Vue.config.productionTip = false

new Vue({
  store: store,
  router: router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home';
import Services from './views/Services';
import Realisations from './views/Realisations';
import About from './views/About';
import Form from './views/Form';
import FormBathroom from './components/Form/FormBathroom/FormBathroom';
import QuoteBathroom from './components/Quote/QuoteBathroom/QuoteBathroom';
import FormAirConditioning from './components/Form/FormAirConditioning/FormAirConditioning';
import QuoteAirConditioning from './components/Quote/QuoteAirConditioning/QuoteAirConditioning';
import FormElectricity from './components/Form/FormElectricity/FormElectricity';
import QuoteElectricity from './components/Quote/QuoteElectricity/QuoteElectricity';
import PersonalInformations from './components/PersonalInformations';
import Rgpd from './views/Rgpd';


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        title: 'DBD Tech - Le partenaire de vos travaux',
        metaTags: [
          {
            name: 'description',
            content: 'DBD Tech vous propose un devis en ligne facile et rapide pour vos projets de construction et de rénovation pour votre salle de bain, l\'électricité et la climatisation.'
          },
          {
            property: 'og:description',
            content: 'DBD Tech vous propose un devis en ligne facile et rapide pour vos projets de construction et de rénovation pour votre salle de bain, l\'électricité et la climatisation.'
          }
        ]
      }
    },
    {
      path: '/services',
      component: Services,
      meta: {
        title: 'DBD Tech - Le partenaire de vos travaux',
        metaTags: [
          {
            name: 'description',
            content: 'DBD Tech est le partenaire de vos travaux : sanitaire, électricité, climatisation, petite maçonnerie, chape, carrelage, isolation ...'
          },
          {
            property: 'og:description',
            content: 'DBD Tech est le partenaire de vos travaux : sanitaire, électricité, climatisation, petite maçonnerie, chape, carrelage, isolation ...'
          }
        ]
      }
    },
    {
      path: '/realisations',
      component: Realisations,
      meta: {
        title: 'DBD Tech - Nos plus belles réalisations',
        metaTags: [
          {
            name: 'description',
            content: 'Découvrez nos plus belles réalisations en travaux d\'intérieur et extérieur'
          },
          {
            property: 'og:description',
            content: 'Découvrez nos plus belles réalisations en travaux d\'intérieur et extérieur'
          }
        ]
      }
    },
    {
      path: '/a-propos',
      component: About,
      meta: {
        title: 'DBD Tech - Découvrez notre histoire',
        metaTags: [
          {
            name: 'description',
            content: 'DBD Tech a vu le jour à Namur et propose des devis en ligne faciles et rapides.'
          },
          {
            property: 'og:description',
            content: 'DBD Tech a vu le jour à Namur et propose des devis en ligne faciles et rapides.'
          }
        ]
      }
    },
    {
      path: '/devis-en-ligne',
      component: Form,
      meta: {
        title: 'DBD Tech - Un devis en ligne simple et rapide',
        metaTags: [
          {
            name: 'description',
            content: 'Réalisez facilement votre devis pour vos travaux de salle de bain, électricité et climatisation.'
          },
          {
            property: 'og:description',
            content: 'Réalisez facilement votre devis pour vos travaux de salle de bain, électricité et climatisation.'
          }
        ]
      }
    },
    {
      path: '/devis-salle-de-bain',
      component: FormBathroom,
      meta: {
        title: 'DBD Tech - Devis salle de bain en ligne',
        metaTags: [
          {
            name: 'description',
            content: 'Devis en ligne pour votre salle de bain, simple et rapide'
          },
          {
            property: 'og:description',
            content: 'Devis en ligne pour votre salle de bain, simple et rapide'
          }
        ]
      }
    },
    {
      path: '/votre-devis-salle-de-bain-personnalise',
      component: QuoteBathroom,
      meta: {
        title: 'DBD Tech - Votre devis personnalisé'
      }
    },
    {
      path: '/devis-climatisation',
      component: FormAirConditioning,
      meta: {
        title: 'DBD Tech - Devis climatisation en ligne',
        metaTags: [
          {
            name: 'description',
            content: 'Devis en ligne pour votre climatisation, simple et rapide'
          },
          {
            property: 'og:description',
            content: 'Devis en ligne pour votre climatisation, simple et rapide'
          }
        ]
      }
    },
    {
      path: '/votre-devis-climatisation-personnalise',
      component: QuoteAirConditioning,
      meta: {
        title: 'DBD Tech - Votre devis personnalisé'
      }
    },
      component: FormElectricity,
      meta: {
        title: 'DBD Tech - Devis électricité en ligne',
        metaTags: [
          {
            name: 'description',
            content: 'Devis en ligne pour votre électricité, simple et rapide'
          },
          {
            property: 'og:description',
            content: 'Devis en ligne pour votre électricité, simple et rapide'
          }
        ]
      }
    },
    {
      path: '/informations-personnelles',
      component: PersonalInformations,
      meta: {
        title: 'DBD Tech - Vos informations personnelles'
      }
    },
    {
      path: '/rgpd',
      component: Rgpd,
      meta: {
        title: 'DBD Tech - RGPD'
      }
    },
    {
      path: '*',
      component: Home,
      meta: {
        title: 'DBD Tech - Le partenaire de vos travaux',
        metaTags: [
          {
            name: 'description',
            content: 'DBD Tech vous propose un devis en ligne facile et rapide pour vos projets de construction et de rénovation pour votre salle de bain, l\'électricité et la climatisation.'
          },
          {
            property: 'og:description',
            content: 'DBD Tech vous propose un devis en ligne facile et rapide pour vos projets de construction et de rénovation pour votre salle de bain, l\'électricité et la climatisation.'
          }
        ]
      }
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
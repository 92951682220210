<template>
  <section class="form formQuote quoteBathroom">
    <div class="container">
      <div class="navigationForm">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li><router-link :to="'/devis-en-ligne'">Devis en ligne</router-link></li>
          <li>Salle de bain</li>
        </ul>
      </div>
      <div class="finalQuote">
        <h2>Récapitulatif de votre devis</h2>
        <div class="totalPrice">
          <h3>Prix estimatif du chantier</h3>
          <p class="htvaPrice">{{ totalPriceHtva | numeral('0.00') }} <span>€ HTVA</span></p>
          <p class="tvacPrice">{{ totalPriceTvac | numeral('0.00') }} <span>€ TVAC {{ pourcentageOfTva }}%</span></p>
          <p>Ce prix est basé sur vos réponses et dépend du niveau de finition choisi pour chaque élément. Dans tous les cas, il sera réévalué après une visite de notre technicien pour un devis plus précis. Il sera alors possible d'ajouter diverses options.</p>
          <p>Le devis ci-dessous s'entend fourniture, pose et raccordement compris.</p>
        </div>
        <div class="quoteDescription">
          <h3>Informations personnelles</h3>
          <table>
            <tr class="twoCols">
              <td>Nom et prénom</td>
              <td>{{ quoteUserInformations.name }}</td>
            </tr>
            <tr class="twoCols">
              <td>Adresse email</td>
              <td>{{ quoteUserInformations.emailAddress}}</td>
            </tr>
            <tr class="twoCols">
              <td>Code postal</td>
              <td>{{ quoteUserInformations.postCode }}</td>
            </tr>
            
          </table>
          <h3>Données de base</h3>
          <table>
            <tr class="twoCols">
              <td>Période de construction du bâtiment</td>
              <td>{{ typeOfConstruction }}</td>
            </tr>
            <tr class="twoCols">
              <td>Surface de la pièce</td>
              <td>{{ areaCalc }} m²</td>
            </tr>
            <tr class="twoCols">
              <td>
                Réutilisation de la tuyauterie existante
                <p v-if="quote.bathroom.supplements.plumbing.toDo">Une augmentation est appliquée à ce devis pour tenir compte de l'installation des nouvelles tuyauteries.</p>
              </td>
              <td>
                {{ quote.bathroom.supplements.plumbing.toDo ? 'NON' : 'OUI' }}
              </td>
            </tr>
          </table>
          <h3 v-if="quote.bathroom.preparation.demolition.type">Préparation du chantier</h3>
          <table v-if="quote.bathroom.preparation.demolition.type">
            <tr class="twoCols">
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
            <tr class="twoCols">
              <td>
                {{ typeOfDemolition.name }}
                <p>{{ typeOfDemolition.description }}</p>
              </td>
              <td>{{ totalPriceOfDemolition  | numeral('0.00') }} €</td>
            </tr>
          </table>
          <h3>Conception</h3>
          <table>
            <tr class="fiveCols">
                <th>Intitulé</th>
                <th>Gamme</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix total</th>
              </tr>
            <tr v-if="quote.bathroom.conception.douche.toDo" class="fiveCols">
              <td>
                {{ typeOfDouche.name }}
                <p>{{ typeOfDouche.description }}</p>
                <p v-if="quote.bathroom.conception.douche.type !== 'cabine'">{{ typeOfParoiDouche.name }} : {{ typeOfParoiDouche.description }}</p>
                <p v-if="quote.bathroom.conception.douche.type !== 'cabine'">{{ typeOfWallDouche.name }} : {{ typeOfWallDouche.description }}</p>
                <!-- <p><a v-if="quote.bathroom.conception.douche.gamme === 1" href="https://www.dbdtech.be/fiches/dbdtech-douche-standard.pdf" target="blank" class="link link-fiche">Fiche technique gamme standard</a><a v-else href="https://www.dbdtech.be/fiches/dbdtech-douche-top.pdf" target="blank" class="link link-fiche">Fiche technique gamme top</a></p> -->
              </td>
              <td class="tdGamme">
                <template v-if="quote.bathroom.conception.douche.type !== 'cabine'">
                  <input type="radio" id="gammeDoucheBasis" name="gammeDouche" :value="1" v-model="quote.bathroom.conception.douche.gamme">
                  <label for="gammeDoucheBasis">Standard</label>
                  <input type="radio" id="gammeDoucheTop" name="gammeDouche" :value="2" v-model="quote.bathroom.conception.douche.gamme">
                  <label for="gammeDoucheTop">Top</label>
                </template>
              </td>
              <td></td>
              <td></td>
              <td>
                {{ totalPriceOfDoucheSet  | numeral('0.00') }} €
              </td>
            </tr>
            <tr v-if="quote.bathroom.conception.baignoire.toDo" class="fiveCols">
              <td>
                {{ typeOfBaignoire.name }}
                <p>{{ typeOfBaignoire.description }}</p>
              </td>
              <td class="tdGamme">
                <input type="radio" id="gammeBaignoireBasis" name="gammeBaignoire" :value="1" v-model="quote.bathroom.conception.baignoire.gamme">
                <label for="gammeBaignoireBasis">Standard</label>
                <input type="radio" id="gammeBaignoireTop" name="gammeBaignoire" :value="2" v-model="quote.bathroom.conception.baignoire.gamme">
                <label for="gammeBaignoireTop">Top</label>
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceOfBaignoireSet  | numeral('0.00') }} €</td>
            </tr>
             <tr v-if="quote.bathroom.conception.sink.toDo" class="fiveCols">
              <td>
                {{ typeOfVasque.name }}
                <p>{{ typeOfVasque.description }}</p>
              </td>
              <td class="tdGamme">
                <input type="radio" id="gammeVasqueBasis" name="gammeVasque" :value="1" v-model="quote.bathroom.conception.sink.vasque.gamme">
                <label for="gammeVasqueBasis">Standard</label>
                <input type="radio" id="gammeVasqueTop" name="gammeVasque" :value="2" v-model="quote.bathroom.conception.sink.vasque.gamme">
                <label for="gammeVasqueTop">Top</label>
              </td>
              <td></td>
              <td></td>
              <td>
                {{ totalPriceOfSinkSet  | numeral('0.00') }} €
              </td>
            </tr>
            <tr v-if="quote.bathroom.conception.wc.toDo" class="fiveCols">
              <td>
                {{ typeOfWc.name }}
                <p>{{ typeOfWc.description }}</p>
              </td>
              <td class="tdGamme">
                <input type="radio" id="gammeWcBasis" name="gammeWc" :value="1" v-model="quote.bathroom.conception.wc.gamme">
                <label for="gammeWcBasis">Standard</label>
                <input type="radio" id="gammeWcTop" name="gammeWc" :value="2" v-model="quote.bathroom.conception.wc.gamme">
                <label for="gammeWcTop">Top</label>
              </td>
              <td>
                <select name="quantity" id="quantity" v-model="quote.bathroom.conception.wc.quantity">
                    <option :value="0">0</option>
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                  </select>
              </td>
              <td>{{ priceOfWc  | numeral('0.00') }} €</td>
              <td>{{ totalPriceOfWc  | numeral('0.00') }} €</td>
            </tr>
          </table>
          <h3 v-if="quote.bathroom.finitions.wall.toDo || quote.bathroom.finitions.ground.toDo || quote.bathroom.finitions.ceiling.toDo">Finitions</h3>
          <table v-if="quote.bathroom.finitions.wall.toDo || quote.bathroom.finitions.ground.toDo || quote.bathroom.finitions.ceiling.toDo">
            <tr class="fiveCols">
                <th>Intitulé</th>
                <th>Gamme</th>
                <th>Quantité</th>
                <th></th>
                <th>Prix total</th>
              </tr>
            <tr v-if="quote.bathroom.finitions.ground.toDo" class="fiveCols">
              <td>
                Sol : {{ typeOfGround.name }}
                <p>{{ typeOfGround.description }}</p>
                <p v-if="(quote.bathroom.conception.douche.toDo && quote.bathroom.conception.douche.type !== 'cabine') || quote.bathroom.conception.baignoire.toDo">La surface recouverte ne comprend pas la baignoire ni la douche (sauf dans le cas d'une cabine)</p>
                <p v-if="quote.bathroom.preparation.demolition.type === 'big'">Ce prix comprend également la chape.</p>
              </td>
              <td class="tdGamme">
                <template v-if="quote.bathroom.finitions.ground.type !== 'plancher'">
                  <input type="radio" id="gammeGroundBasis" name="gammeGround" :value="1" v-model="quote.bathroom.finitions.ground.gamme">
                  <label for="gammeGroundBasis">Standard</label>
                  <input type="radio" id="gammeGroundTop" name="gammeGround" :value="2" v-model="quote.bathroom.finitions.ground.gamme">
                  <label for="gammeGroundTop">Top</label>
                </template>
              </td>
              <td>{{ areaGroundCalc | numeral('0.00') }} m²</td>
              <td></td>
              <td>{{ totalPriceOfGround | numeral('0.00') }} €</td>
            </tr>
            <tr v-if="quote.bathroom.finitions.wall.toDo" class="fiveCols">
              <td>
                Mur : {{ typeOfWall.name }}
                <p>{{ typeOfWall.description }}</p>
                <p><strong>{{ quote.bathroom.finitions.wall.zones == 'allWall' ? "Tous les murs" : "Uniquement les zones humides (baignoire, wc, lavabo, hors douche)" }}</strong></p>
              </td>
              <td class="tdGamme">
                <template v-if="quote.bathroom.finitions.wall.type !== 'panneau'">
                  <input type="radio" id="gammeWallBasis" name="gammeWall" :value="1" v-model="quote.bathroom.finitions.wall.gamme">
                  <label for="gammeWallBasis">Standard</label>
                  <input type="radio" id="gammeWallTop" name="gammeWall" :value="2" v-model="quote.bathroom.finitions.wall.gamme">
                  <label for="gammeWallTop">Top</label>
                </template>
              </td>
              <td v-if="quote.bathroom.finitions.wall.zones === 'allWall'">{{ wallAreaCalc | numeral('0.00') }} m²</td>
              <td v-else>{{ wetWallAreaCalc | numeral('0.00') }} m²</td>
              <td></td>
              <td>{{ totalPriceOfWall | numeral('0.00') }} €</td>
            </tr>
            <tr v-if="quote.bathroom.finitions.ceiling.toDo" class="fiveCols">
              <td>
                Plafond
                <p>Avec {{ areaCalc > 6 ? 4 : 2 }} spots encastrés</p>
              </td>
              <td>
              </td>
              <td>{{ areaCalc | numeral('0.00') }} m²</td>
              <td></td>
              <td>{{ totalPriceOfCeiling | numeral('0.00') }} €</td>
            </tr>
          </table>
          <h3 v-if="quote.bathroom.supplements.plumbing.toDo">Travaux divers</h3>
          <table v-if="quote.bathroom.supplements.plumbing.toDo">
            <tr class="threeCols">
              <td>Tuyauterie</td>
              <td>{{ totalOfWaterPoints }} {{ totalOfWaterPoints > 1 ? "pces" : "pce" }}</td>
              <td>{{ totalPriceOfWaterPoints  | numeral('0.00') }} €</td>
            </tr>
          </table>
        </div>
         <pdf-quote-bathroom></pdf-quote-bathroom>        
      </div>
     
      <div class="quoteActions">
        <div class="btn-group">
          <button class="btn btn-dark btn-action" @click="savePdf">Enregistrer le devis</button>
          <button class="btn btn-secondary btn-action" @click="sendToAll">Envoyer le PDF par mail</button>
        </div>
        
      </div>
      <call-me-quote></call-me-quote>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import emailjs from 'emailjs-com';
import pdfQuoteBathroom from '../../../components/Quote/QuoteBathroom/PdfQuoteBathroom.vue';
import callMeQuote from '../CallMeQuote.vue';

export default {
  components: {
    pdfQuoteBathroom,
    callMeQuote
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const priceDiv = document.querySelector('.totalPrice');
      const quoteDescriptionDiv = document.querySelector('.quoteDescription');
      const offsetPriceDiv = priceDiv.offsetTop;
      const newMarginTop = priceDiv.offsetHeight + 120 + 'px';

      if(window.scrollY > offsetPriceDiv) {
        priceDiv.classList.add('fixed');
        quoteDescriptionDiv.style.marginTop = newMarginTop;
        
      } else {
        priceDiv.classList.remove('fixed');
        quoteDescriptionDiv.style.marginTop = 0;
      }
    },
    generatePdf () {

      const doc = new jsPDF();
      let imgData = this.logo.img;
      let format = 'png';
      let x = 5;
      let y = 5;
      let width = 419/5;
      let height = 180/5;
      let alias = "logo";
      let compression = "none";
      let rotation = 0;

      doc.setFontSize(12);
      doc.setFont("helvetica");
      // Logo
      doc.addImage(imgData, format, x, y, width, height, alias, compression, rotation);
      // DBD Tech informations
      const dbdTitle = 'DBD Tech';
      const dbdAddress = 'Rue de la Basse Sambre, 10\nBE 5150 Floreffe';
      const dbdEmail = 'info@dbdtech.be';
      const dbdSite = 'www.dbdtech.be';
      const dbdPhone = '+(32)472/23.16.44';
      const dbdTva = 'TVA BE0765.717.010';
      const dbdIban = 'BE16 1431 1535 9474';
      doc.text(15, 50, dbdTitle + '\n' + dbdAddress + '\n' + dbdEmail +'\n' + dbdSite + '\n' + dbdPhone + '\n' + dbdTva + '\n' + dbdIban);
      // User informations
      let name = this.quoteUserInformations.name;
      let email = this.quoteUserInformations.emailAddress;
      let postCode = this.quoteUserInformations.postCode;
      doc.text(125, 50, name + '\n' + email + '\n' + postCode);
      
      // Quote Informations
      let quoteDate = this.quote.date;
      doc.text(125, 100, quoteDate);

      let quoteNumber = 'Devis n° ' + this.quote.quoteNumber + '\nVersion ' + this.quote.version;
      doc.text(15, 100, quoteNumber);
      doc.autoTable({
        head: [['Informations de base']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        startY: 120
      });
      autoTable(doc, { html: '#quoteToPdfBasis', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      doc.autoTable({
        head: [['Préparation du chantier']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfPreparation', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'right', minCellWidth: 30 }}});
      
      doc.autoTable({
        head: [['Conception']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfConception',  headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'right', minCellWidth: 30 }, 4: { halign: 'right', minCellWidth: 30 } }});
      
      doc.autoTable({
        head: [['Finitions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
      });
      autoTable(doc, { html: '#quoteToPdfFinition', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 2: { halign: 'right', minCellWidth: 30 } }});
      
      if(this.quote.bathroom.supplements.plumbing.toDo) {
        doc.autoTable({
          head: [['Travaux divers']],
          headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14}
        });
        autoTable(doc, { html: '#quoteToPdfDiversBathroom', headStyles: {fillColor: [116, 116, 116], textColor: [255, 255, 255], halign: 'center'}, columnStyles: { 1: { halign: 'center' }, 2: { halign: 'right', minCellWidth: 30 }}});
      }
      
      autoTable(doc, { html: '#quoteToPdfPrices', styles: {halign: 'right', fontStyle: 'bold'},alternateRowStyles: {fillColor: false}});
      

      // Conditions
      doc.autoTable({
        head: [['Conditions']],
        headStyles: {fillColor: false, textColor: [0, 0, 0], fontSize: 14},
        body: [
          ['Validité du devis : 30 jours.\nCe devis s\'entend fourniture, pose et raccordement compris.\nTout devis doit faire l\'objet d\'une visite sur place avant validation définitive. Il est toujours possible de modifier les accessoires après notre visite, sous réserve d\'adaptation des prix. En cas d\'intérêt, merci d\'adresser votre demande à l\'adresse client@dbdtech.be afin d\'être recontacté.']
        ],
        bodyStyles: {fillColor: false, textColor: [0, 0, 0]},
        alternateRowStyles: {fillColor: false}
      });

      return doc;
      
    },
    toBase64() {
      let doc = this.generatePdf();
      let pdfBase64 = doc.output('datauristring');
      return pdfBase64;
    },
    pdfName() {
      return this.quote.quoteNumber + '-v' + this.quote.version + '.pdf';
    },
    sendToAll() {
      this.quote.version++;
      this.sendPdf('admin');
      this.sendPdf('user');
    },
    sendPdf(to_user) {
      let waiting = document.querySelector('#waiting');
      waiting.classList.add('active');
      let pdfBase64 = this.toBase64();
      let templateId = '';
      let name = this.pdfName();
      if(to_user === 'admin') {
        templateId = 'template_0dsylcs';
      } else {
        templateId = 'template_4mq3hom';
      }
      emailjs.send('service_0g7pobn', templateId, {
                  to_name: "Jérôme",
                  user_name: this.quoteUserInformations.name,
                  user_postCode: this.quoteUserInformations.postCode,
                  user_email: this.quoteUserInformations.emailAddress,
                  pdf_file: pdfBase64,
                  pdf_name: name
              }, 'user_HbY4OMU3yW41VYggzuODG').then((result) => {
                      console.log('SUCCESS!', result.status, result.text);
                      waiting.classList.remove('active');

                  }, (error) => {
                      console.log('FAILED...', error);
                      waiting.classList.remove('active');
                  });
    
    },
    savePdf() {
      this.quote.version++;
      console.log('save');
      let doc = this.generatePdf();
      let name = this.pdfName();
      doc.save(name);
      this.sendPdf('admin');
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'areaCalc',
      'areaGroundCalc',
      'perimeterCalc',
      'wallAreaCalc',
      'wetWallAreaCalc',
      'typeOfWc',
      'priceOfWc',
      'totalPriceOfWc',
      'typeOfBaignoire',
      'priceOfBaignoireSet',
      'totalPriceOfBaignoireSet',
      'typeOfDouche',
      'priceOfDouche',
      'priceOfMixerDouche',
      'typeOfParoiDouche',
      'priceOfParoiDouche',
      'typeOfWallDouche',
      'priceOfPatchingWallDouche',
      'priceOfFinitionWallDouche',
      'priceOfDoucheSet',
      'totalPriceOfDoucheSet',
      'typeOfVasque',
      'priceOfMiror',
      'priceOfMixerSink',
      'priceOfEquipments',
      'priceOfSinkSet',
      'totalPriceOfSinkSet',
      'typeOfGround',
      'flatRateOfGroundScreed',
      'totalPriceOfGroundType',
      'totalPriceOfPlinthe',
      'totalPriceOfGround',
      'typeOfWall',
      'flatRateOfWallScreed',
      'priceOfWallType',
      'totalPriceOfWallType',
      'totalPriceOfWall',
      'totalPriceOfCeiling',
      'typeOfConstruction',
      'typeOfDemolition',
      'pourcentageOfTva',
      'totalOfWaterPoints',
      'totalPriceOfDemolition',
      'totalPriceOfWaterPoints',
      'totalPriceHtva',
      'totalPriceTvac'
    ]), 
  }
}
</script>

<style>

</style>
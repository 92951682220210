<template>
  <fieldset>
    <h2>Faut-il installer une vasque ou un lavabo ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="sink" :value="true" v-model="quote.bathroom.conception.sink.toDo" @click="scrollToNextQuestion(0)">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="sink" :value="false" v-model="quote.bathroom.conception.sink.toDo" @click="validateStep7">
        <label for="no">Non</label>
      </li>
    </ul>
    <div v-show="quote.bathroom.conception.sink.toDo" class="moreQuestions">
      <h3>Quel type d'installation souhaitez-vous ?</h3>
      <ul >
        <li>
          <input type="radio" id="lavabo" name="vasqueType" value="lavabo" v-model="quote.bathroom.conception.sink.vasque.type" @click="scrollToNextQuestion(1)">
          <label for="lavabo">Lavabo mural</label>
        </li>
        <li>
          <input type="radio" id="vasque" name="vasqueType" value="vasque" v-model="quote.bathroom.conception.sink.vasque.type" @click="scrollToNextQuestion(1)">
          <label for="vasque">Vasque</label>
        </li>
      </ul>
      <p class="moreInformations">Une vasque doit obligatoirement être installée sur un meuble.</p>
    </div>
    <div v-show="quote.bathroom.conception.sink.vasque.type != null && quote.bathroom.conception.sink.toDo" class="moreQuestions">
      <h3 v-if="quote.bathroom.conception.sink.vasque.type === 'vasque'">Combien de vasques faut-il ?</h3>
      <h3 v-else>Combien de lavabos faut-il ?</h3>
      <ul >
        <li>
          <input type="radio" id="simple" name="sinkType" :value="1" v-model="quote.bathroom.conception.sink.vasque.quantity" @click="validateStep7">
          <label for="simple">{{ quote.bathroom.conception.sink.vasque.type === 'vasque' ? 'Une' : 'Un'}}</label>
        </li>
        <li>
          <input type="radio" id="double" name="sinkType" :value="2" v-model="quote.bathroom.conception.sink.vasque.quantity" @click="validateStep7">
          <label for="double">Deux</label>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    scrollToNextQuestion(index) {
      setTimeout(() => {
        const moreQuestionsDivs = document.querySelectorAll('.moreQuestions');
        const scrollPosition = moreQuestionsDivs[index].offsetTop - 100;
        scroll({
          top: scrollPosition,
          behavior: "smooth"
        });
      }, 500)
    },
    validateStep7() {
      this.quote.validStep = 7; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Quelle est la surface de la pièce ?</h2>
    <div class="form-group surface">
      <div class="input-group">
        <label for="length">Longueur</label>
        <input type="number" id="length" name="length" v-model="quote.roomInformations.length">
        <div class="unity">M</div>
      </div>
      <div class="input-group">
        <label for="width">Largeur</label>
        <input type="number" id="width" name="width" v-model="quote.roomInformations.width">
        <div class="unity">M</div>
      </div>
      <button class="btn btn-action btn-secondary" @click.prevent="validateStep2" :disabled="!validateInput">
        Valider
      </button>
    </div>
    <p class="inputError" v-show="!validateInput">La longueur et la largeur doivent être supérieures à 0</p>

  </fieldset>
</template>

<script>

export default {

  props: ['quote', 'currentStep'],
  methods: {
    validateStep2() {
      if(this.quote.validStep < this.currentStep) {
        this.quote.validStep = 2; 
      }
      this.$emit('next-step');
    }
  },
  computed: {
    validateInput() {
      if(this.quote.roomInformations.length > 0 && this.quote.roomInformations.width > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>

</style>
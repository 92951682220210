<template>
<div id="pdfQuoteBathroom">
  <table id="quoteToPdfBasis" class="quotePdf">
      <thead>
          <tr>
              <th>Intitulé</th>
              <th>Détail</th>
          </tr>
      </thead>
      <tbody>
        <tr>
            <td>Période de construction du bâtiment</td>
            <td>{{ typeOfConstruction }}</td>
        </tr>
        <tr>
            <td>Surface de la pièce</td>
            <td>{{ areaCalc }} m²</td>
        </tr>
        <tr>
            <td>
            Réutilisation de la tuyauterie existante
            <p v-if="quote.bathroom.supplements.plumbing.toDo"><br>Une augmentation est appliquée à ce devis pour tenir compte de l'installation des nouvelles tuyauteries.</p>
            </td>
            <td>
            {{ quote.bathroom.supplements.plumbing.toDo ? 'NON' : 'OUI' }}
            </td>
        </tr>
      </tbody>
            
    </table>

    <table id="quoteToPdfPreparation" class="quotePdf" v-if="quote.bathroom.preparation.demolition.type">
        <thead>
            <tr>
                <th>Intitulé</th>
                <th>Prix total</th>
              </tr>
        </thead>
        <tbody>
<tr>
              <td>
                {{ typeOfDemolition.name }}<br>
                <p>{{ typeOfDemolition.description }}</p>
              </td>
              <td>{{ totalPriceOfDemolition  | numeral('0.00') }} €</td>
            </tr>
        </tbody>
            
            
          </table>

          <table id="quoteToPdfConception" class="quotePdf">
              <thead>
                <tr>
                  <th>Intitulé</th>
                  <th>Gamme</th>
                  <th>Quantité</th>
                  <th>Prix unitaire</th>
                  <th>Prix total</th>
                </tr>
              </thead>
            <tbody>
            <tr v-if="quote.bathroom.conception.douche.toDo">
              <td>
                {{ typeOfDouche.name }}<br>
                <p>{{ typeOfDouche.description }}</p><br>
                <p v-if="quote.bathroom.conception.douche.type !== 'cabine'">{{ typeOfParoiDouche.name }} : {{ typeOfParoiDouche.description }}</p><br>
                <p v-if="quote.bathroom.conception.douche.type !== 'cabine'">{{ typeOfWallDouche.name }} : {{ typeOfWallDouche.description }}</p>
              </td>
              <td>
                  {{ quote.bathroom.conception.douche.gamme == 1 ? "Standard" : "Top" }}
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceOfDoucheSet  | numeral('0.00') }} €</td>
            </tr>
            <tr v-if="quote.bathroom.conception.baignoire.toDo">
              <td>
                {{ typeOfBaignoire.name }}<br>
                <p>{{ typeOfBaignoire.description }}</p>
              </td>
              <td>
                {{ quote.bathroom.conception.baignoire.gamme == 1 ? "Standard" : "Top" }}
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceOfBaignoireSet  | numeral('0.00') }} €</td>
            </tr>
             <tr v-if="quote.bathroom.conception.sink.toDo">
              <td>
                {{ typeOfVasque.name }}<br>
                <p>{{ typeOfVasque.description }}</p>
              </td>
              <td>
                {{ quote.bathroom.conception.sink.vasque.gamme == 1 ? "Standard" : "Top" }}
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceOfSinkSet  | numeral('0.00') }} €</td>
            </tr>
            <tr v-if="quote.bathroom.conception.wc.toDo">
              <td>
                {{ typeOfWc.name }}<br>
                <p>{{ typeOfWc.description }}</p>
              </td>
              <td>
                {{ quote.bathroom.conception.wc.gamme == 1 ? "Standard" : "Top" }}
              </td>
              <td>
                {{quote.bathroom.conception.wc.quantity}}
              </td>
              <td>{{ priceOfWc  | numeral('0.00') }} €</td>
              <td>{{ totalPriceOfWc  | numeral('0.00') }} €</td>
            </tr>
              </tbody>
            
            
          </table>

          <table id="quoteToPdfFinition" class="quotePdf">
              <thead>
                <tr>
                <th>Intitulé</th>
                <th>Quantité</th>
                <th>Prix total</th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="quote.bathroom.finitions.ground.toDo">
                    <td>
                        Sol : {{ typeOfGround.name }}<br>
                        <p>{{ typeOfGround.description }}</p><br>
                        <p v-if="(quote.bathroom.conception.douche.toDo && quote.bathroom.conception.douche.type !== 'cabine') || quote.bathroom.conception.baignoire.toDo">La surface recouverte ne comprend pas la baignoire ni la douche (sauf dans le cas d'une cabine)</p>
                        <p v-if="quote.bathroom.preparation.demolition.type === 'big'">Ce prix comprend également la chape.</p>
                    </td>
                    <td></td>
                    <td>{{ totalPriceOfGround | numeral('0.00') }} €</td>
                </tr>
                <tr v-if="quote.bathroom.finitions.wall.toDo">
                    <td>
                        Mur : {{ typeOfWall.name }}<br>
                        <p>{{ typeOfWall.description }}</p><br>
                        <p><strong>{{ quote.bathroom.finitions.wall.zones == 'allWall' ? "Tous les murs" : "Uniquement les zones humides (baignoire, wc, lavabo, hors douche)" }}</strong></p>
                    </td>
                    <td v-if="quote.bathroom.finitions.wall.zones === 'allWall'">{{ wallAreaCalc | numeral('0.00') }} m²</td>
                    <td v-else>{{ wetWallAreaCalc | numeral('0.00') }} m²</td>
                    <td>{{ totalPriceOfWall | numeral('0.00') }} €</td>
                </tr>
                <tr v-if="quote.bathroom.finitions.ceiling.toDo">
                  <td>
                    Plafond
                    <p>Avec 4 spots encastrés</p>
                  </td>
                  <td>{{ areaCalc | numeral('0.00') }} m²</td>
                  <td>{{ totalPriceOfCeiling | numeral('0.00') }} €</td>
                </tr>
              </tbody>
            
            
          </table>
          
          <table id="quoteToPdfDiversBathroom" class="quotePdf" v-if="quote.bathroom.supplements.plumbing.toDo">
            <tr>
              <td>Tuyauterie</td>
              <td>{{ totalOfWaterPoints }} {{ totalOfWaterPoints > 1 ? "pces" : "pce" }}</td>
              <td>{{ totalPriceOfWaterPoints  | numeral('0.00') }} €</td>
            </tr>
          </table>


      <table id="quoteToPdfPrices" class="quotePdf">
        <tbody>
              <tr class="twoCols">
                <td>Prix total Htva</td>
                <td>{{ totalPriceHtva | numeral('0.00') }} €</td>
              </tr>
              <tr class="twoCols">
                <td>Prix total Tvac {{ pourcentageOfTva }}%</td>
                <td>{{ totalPriceTvac | numeral('0.00') }} €</td>
              </tr>
            </tbody>
      </table>
      
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    },
    prices() {
      return this.$store.state.prices;
    },
    ...mapGetters([
      'areaCalc',
      'areaGroundCalc',
      'perimeterCalc',
      'wallAreaCalc',
      'wetWallAreaCalc',
      'typeOfWc',
      'priceOfWc',
      'totalPriceOfWc',
      'typeOfBaignoire',
      'priceOfBaignoireSet',
      'totalPriceOfBaignoireSet',
      'typeOfDouche',
      'typeOfParoiDouche',
      'priceOfDoucheSet',
      'typeOfWallDouche',
      'totalPriceOfDoucheSet',
      'typeOfVasque',
      'priceOfSinkSet',
      'totalPriceOfSinkSet',
      'typeOfGround',
      'flatRateOfGroundScreed',
      'totalPriceOfGroundType',
      'totalPriceOfPlinthe',
      'totalPriceOfGround',
      'typeOfWall',
      'flatRateOfWallScreed',
      'totalPriceOfWallType',
      'totalPriceOfWall',
      'totalPriceOfCeiling',
      'typeOfConstruction',
      'typeOfDemolition',
      'pourcentageOfTva',
      'totalOfWaterPoints',
      'totalPriceOfDemolition',
      'totalPriceOfWaterPoints',
      'totalPriceHtva',
      'totalPriceTvac'
    ]), 
  }
}
</script>

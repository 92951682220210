<template>
  <section class="form formAirConditioning">
    <div class="container">
      <div class="navigationForm toShow">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li><router-link :to="'/devis-en-ligne'">Devis en ligne</router-link></li>
          <li>Climatisation</li>
        </ul>
        <div class="currentStep" :style="{ backgroundSize: (100 / totalOfSteps * quote.validStep ) + '%, cover' }">
          {{ currentStep }}
        </div>
      </div>
      <div class="previousStep btn btn-round btn-primary" @click="previousStep" v-show="currentStep > 1"></div>
      <form class="quoteForm">
          <keep-alive>
            <component :is="currentStepComponent" :currentStep="currentStep" @next-step="nextStep"></component> 
          </keep-alive>
      </form>
      <div class="nextStep btn btn-round btn-primary" @click="nextStep" v-show="currentStep < totalOfSteps && quote.validStep >= currentStep"></div>
    </div>
    <div class="validateForm" v-show="quote.isValid && !errors">
        <h2>Avez-vous terminé votre devis?</h2>
        <p>Avant de valider, vous pouvez revenir aux étapes précédentes pour modifier vos choix.</p>
        <div>
          <button class="btn btn-secondary btn-action" @click="validateForm">Je valide !</button>
        </div>
      </div>
  </section>
</template>

<script>

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  data() {
    return {
      currentStep: 1,
      totalOfSteps: 5
    }
  },
  computed: {
    quote() {
      return this.$store.state.quote;
    },
    currentStepComponent() {
      return 'step' + this.currentStep;
    },
    errors() {
      if(this.quote.airConditioning.rooms.bedroom.quantity >= 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    scrollToTop () {
      let currentYOffset = self.pageYOffset;
      const initYOffset = currentYOffset;

      const intervalId = setInterval(function(){
        currentYOffset -= initYOffset*0.05; 
        document.body.scrollTop = currentYOffset ;
        document.documentElement.scrollTop = currentYOffset;

        if(self.pageYOffset == 0){
          clearInterval(intervalId);
        }
      }, 20);
    },
    nextStep() {
      let newStep = this.currentStep + 1;
      setTimeout(() => {
        if(newStep <= this.totalOfSteps) {
          if(this.quote.basisInformations.dateOfConstruction === 0 && newStep === 3) {
            this.currentStep = newStep + 1;
            console.log(newStep);
            this.scrollToTop();
          } else {
            this.currentStep = newStep;
            console.log(newStep);
            this.scrollToTop();
          }
          
        } else {
          console.log("Ce step n'existe pas");
        }      
      }, 500);
    },
    previousStep() {
      let newStep = this.currentStep - 1;
      setTimeout(() => {
        if(newStep > 0) {
          if(this.quote.basisInformations.dateOfConstruction === 0 && newStep === 3) {
            this.currentStep = newStep - 1;
            console.log(newStep);
            this.scrollToTop();
          } else {
            this.currentStep = newStep;
            console.log(newStep);
            this.scrollToTop();
          }
        } else {
          console.log("Ce step n'existe pas");
        }      
      }, 500);
    },
    goToStep(step) {
      let newStep = step;
      setTimeout(() => {
        if(newStep <= this.totalOfSteps && newStep > 0) {
            this.currentStep = newStep;
            console.log(newStep);
            this.scrollToTop();
        } else {
          console.log("Ce step n'existe pas");
        }      
      }, 500);
    },
    validateForm() {
      this.$router.push('informations-personnelles');
    }
  }
}
</script>

<style>

</style>
<template>
  <div id="app">
    <the-header></the-header>
    <main>
      <router-view></router-view>
    </main>
    <the-footer></the-footer>
    <div id="scrollTop" class="btn btn-secondary" @click="goToTop"></div>
    <div id="waiting">
      <div id="imageWaiting">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from './views/Global/TheHeader.vue';
import TheFooter from './views/Global/TheFooter.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const scrollTop = document.querySelector('#scrollTop');
      if(window.scrollY > 500) {
        scrollTop.classList.add('active');
      } else {
        scrollTop.classList.remove('active');
      } 
    },
    goToTop() {
      let currentYOffset = self.pageYOffset;
      const initYOffset = currentYOffset;

      const intervalId = setInterval(function(){
        currentYOffset -= initYOffset*0.05; 
        document.body.scrollTop = currentYOffset ;
        document.documentElement.scrollTop = currentYOffset;

        if(self.pageYOffset == 0){
          clearInterval(intervalId);
        }
      }, 20);
    }
  }
}
</script>

<style lang="scss">

/* Le fichier styles.scss sera chargé automatiquement */

</style>

<template>
  <div>
    <form-slider :services="services"></form-slider>
  </div>
</template>

<script>

import FormSlider from '../components/Form/FormSlider.vue';

export default {
  components: {
    FormSlider
  },
  computed: {
    services() {
      return this.$store.state.services;
    }
  }
}
</script>

<style>

</style>
<template>
    <section class="page about">
      <div class="container">
        <ul class="breadcrumbs">
          <li><router-link :to="'/'">Accueil</router-link></li>
          <li>A propos de nous</li>
        </ul>
        <header>
          <h1>DBD Tech : trois amis pour un projet commun !</h1>
        </header>
        <p>La société DBD Tech est née d’un projet commun entre 3 amis de longue date. Nos expériences personnelles dans le monde de la construction et du bâtiment ont permis de penser DBD Tech afin qu’elle soit une entreprise de confiance dans vos projets de travaux.</p>

        <p>Nous voulons être votre partenaire de référence en vous proposant un service répondant à vos besoins et vos moyens. Le travail de qualité, la flexibilité et une attitude positive sont les qualités que nous voulons mettre en avant dans nos collaborations.</p>

        <p>Jeune, dynamique, réactif, DBD Tech veut être un acteur actif dans le monde de la construction. Notre souhait est de devenir la solution la plus évidente pour vos travaux. Vous guider et vous accompagner sera notre rôle essentiel.</p>

        <p>Nous sommes impatients de vous accompagner dans vos projets !</p>
      </div>
      
      
    </section>
</template>

<script>

export default {
  
}
</script>

<style>

</style>
<template>
  <fieldset>
    <h2>Faut-il installer une alarme ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="alarm" :value="true" v-model="quote.electricity.alarm.toDo" @click="checkForm">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="alarm" :value="false" v-model="quote.electricity.alarm.toDo" @click="checkForm">
        <label for="no">Non</label>
      </li>
    </ul>
  <p class="moreInformations">De base, l'alarme comprend 5 détecteurs, 2 claviers, 1 sirène intérieure et 1 sirène extérieure. La certification Insert est incluse.</p>
  </fieldset>
</template>

<script>



export default {

  computed: {
    quote() {
      return this.$store.state.quote;
    },
    initElec() {
      return this.$store.state.initElec;
    },
  },
  methods: {
    checkForm() {
      if(this.quote.electricity.rooms.bedroom.quantity > 0) {
        this.quote.electricity.rooms.bedroom.toDo = true;
        for(let i = 0; i < this.quote.electricity.rooms.bedroom.quantity; i++) {
          this.quote.electricity.rooms.bedroom.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.bedroom)));
        }
      } else {
        this.quote.electricity.rooms.bedroom.toDo = false;
      }
      if(this.quote.electricity.rooms.bathroom.quantity > 0) {
        this.quote.electricity.rooms.bathroom.toDo = true;
        for(let i = 0; i < this.quote.electricity.rooms.bathroom.quantity; i++) {
          this.quote.electricity.rooms.bathroom.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.bathroom)));
        }
      } else {
        this.quote.electricity.rooms.bathroom.toDo = false;
      }
      if(this.quote.electricity.rooms.wc.quantity > 0) {
        this.quote.electricity.rooms.wc.toDo = true;
        for(let i = 0; i < this.quote.electricity.rooms.wc.quantity; i++) {
          this.quote.electricity.rooms.wc.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.wc)));
        }
      } else {
        this.quote.electricity.rooms.wc.toDo = false;
      }
      if(this.quote.electricity.rooms.kitchen.toDo) {
        this.quote.electricity.rooms.kitchen.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.kitchen)));
      }
      if(this.quote.electricity.rooms.living.toDo) {
        this.quote.electricity.rooms.living.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.living)));
      }
      if(this.quote.electricity.rooms.diningRoom.toDo) {
        this.quote.electricity.rooms.diningRoom.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.diningRoom)));
      }
      if(this.quote.electricity.rooms.luandry.toDo) {
        this.quote.electricity.rooms.luandry.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.luandry)));
      }
      if(this.quote.electricity.rooms.grenier.toDo) {
        this.quote.electricity.rooms.grenier.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.grenier)));
      }
      if(this.quote.electricity.rooms.bureau.toDo) {
        this.quote.electricity.rooms.bureau.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.bureau)));
      }
      if(this.quote.electricity.rooms.garage.toDo) {
        this.quote.electricity.rooms.garage.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.garage)));
      }
      if(this.quote.electricity.rooms.cellar.toDo) {
        this.quote.electricity.rooms.cellar.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.cellar)));
      }
      if(this.quote.electricity.rooms.gardenShed.toDo) {
        this.quote.electricity.rooms.gardenShed.listOfElecEquipment.push(JSON.parse(JSON.stringify(this.initElec.gardenShed)));
      }
      if(this.quote.electricity.renovation.level === "full") {
        this.quote.electricity.technic.tableau = true;
        this.quote.electricity.technic.reception = true;
      }
      this.quote.validStep = 9; 
      this.quote.isValid = true;
    }
  }
}
</script>

<style>

</style>
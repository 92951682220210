<template>
  <fieldset>
    <h2>Combien de WC faut-il équiper ?</h2>
    <div class="form-group">
      <div class="input-group">
        <label for="quantity">Nombre de WC</label>
        <select name="quantity" id="quantity" autofocus v-model="quote.electricity.rooms.wc.quantity">
          <option :value="0">0</option>
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
        </select>
      </div>
      <button class="btn btn-action btn-secondary" @click.prevent="validateStep5" :disabled="!validateInput">
        Valider
      </button>
    </div>
  <p class="inputError" v-show="!validateInput">Le nombre doit être supérieur ou égal à 0</p>
  </fieldset>
</template>

<script>



export default {

  props: ['currentStep'],

  computed: {
    quote() {
      return this.$store.state.quote;
    },
    validateInput() {
      if(this.quote.electricity.rooms.wc.quantity >= 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    validateStep5() {
      if(this.quote.validStep < this.currentStep) {
        this.quote.validStep = 5; 
      }
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>
<template>
<div class="callMeQuote">
        
        <form id="formCallMe" @submit.prevent="sendCallMe" v-show="!confirmSend">
          <h3 style="text-align: center">Souhaitez-vous qu'on vous appelle ?</h3>
 
          <div class="form-group required">
            <label for="phoneNumber">Numéro de téléphone</label>
            <input type="tel" id="phoneNumber" name="phoneNumber" v-model="quoteUserInformations.phoneNumber" required>
          </div>

          <div class="buttons-group">
            <button class="btn btn-action btn-dark">Appelez-moi !</button>
          </div>
        </form>
        <form v-show="confirmSend" style="text-align: center">
          <h3>Formulaire envoyé avec succès</h3>
          <p>Nous vous recontacterons dans les plus brefs délais.</p>
        </form>
    </div>
</template>

<script>

import emailjs from 'emailjs-com';

export default {
    data() {
        return {
            confirmSend: false
        }
    },
    computed: {
    quoteUserInformations() {
      return this.$store.state.quoteUserInformations;
    },
    quote() {
      return this.$store.state.quote;
    }
  },
  methods: {
      sendCallMe() {
      let waiting = document.querySelector('#waiting');
      let templateId = 'template_o52ng9w';
      waiting.classList.add('active');
      let typeQuote = '';
      if(this.quote.type === "bathroom") {
        typeQuote = "SDB";
      } else if (this.quote.type === "airConditioning") {
        typeQuote = "AirCo";
      } else if (this.quote.type === "electricity") {
        typeQuote = "Elec";
      }
      emailjs.send('service_0g7pobn', templateId, {
                  to_name: "Jérôme",
                  user_name: this.quoteUserInformations.name,
                  user_postCode: this.quoteUserInformations.postCode,
                  user_email: this.quoteUserInformations.emailAddress,
                  user_phone: this.quoteUserInformations.phoneNumber,
                  quote_number: this.quote.quoteNumber,
                  quote_version: this.quote.version,
                  type: typeQuote
              }, 'user_HbY4OMU3yW41VYggzuODG').then((result) => {
                      console.log('SUCCESS!', result.status, result.text);
                      this.confirmSend = true;
                      waiting.classList.remove('active');      
                  }, (error) => {
                      console.log('FAILED...', error);
                      waiting.classList.remove('active');
                  });
    }

  }
}
</script>

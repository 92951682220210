<template>
  <fieldset>
    <h2>Peut-on réutiliser les tuyauteries existantes ?</h2>
    <ul>
      <li>
        <input type="radio" id="yes" name="plumbing" :value="false" v-model="quote.bathroom.supplements.plumbing.toDo" @click="validateStep4">
        <label for="yes">Oui</label>
      </li>
      <li>
        <input type="radio" id="no" name="plumbing" :value="true" v-model="quote.bathroom.supplements.plumbing.toDo" @click="validateStep4">
        <label for="no">Non</label>
      </li>
      <li>
        <input type="radio" id="dontKnow" name="plumbing" value="dontKnow" v-model="quote.bathroom.supplements.plumbing.toDo" @click="validateStep4">
        <label for="dontKnow">Je ne sais pas</label>
      </li>
    </ul>
    <p class="moreInformations">Dans le doute, cliquez sur "Je ne sais pas"</p>

  </fieldset>
</template>

<script>

export default {

  props: ['quote'],
  methods: {
    validateStep4() {
      this.quote.validStep = 4; 
      this.$emit('next-step');
    }
  }
}
</script>

<style>

</style>